import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PlainValue from "./PlainValue";
import {securityService} from "../_services/security.service";

function TypeaheadControl( { name, rules, validationMessages, value, placeholder, onChange, disabled, className, options }) {
	const { setValue, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

	useEffect(() => {
		const newValue = defVal;
		setCurrentValue(newValue);
		if ( accessToProperty.ready && accessToProperty.write ) {
			setValue(name, newValue);
		}
	}, [value, setValue, name, defVal, accessToProperty.ready, accessToProperty.write]);

	const getControl = () => {
		return (
			<>
				<Controller
					control={ control }
					name={ name }
					rules={ rules }
					defaultValue={ currentValue }
					render={ ( { field, fieldState } ) =>
						<>
							<Typeahead
								id={ name }
								onChange={ ( selection ) => {
									setCurrentValue( selection[0] );
									field.onChange( selection[0] );
									if ( onChange ) {
										onChange( selection[0] );
									}
								} }
								onInputChange={ ( input ) => {
									setCurrentValue( input );
									field.onChange( input );
									if ( onChange ) {
										onChange( input );
									}
								} }
								onBlur={ field.onBlur }
								defaultSelected={ [value] }
								value={ field.value }
								name={ field.name }
								ref={ field.ref }
								options={ options }
								className={ ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ) }
								isInvalid={ !!fieldState.error }
								placeholder={ placeholder }
								disabled={ disabled }
							/>
							<Form.Control.Feedback type="invalid">
								{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
							</Form.Control.Feedback>
						</>
					}
				/>
			</>
		)
	}

	const getElement = () => {
		if ( accessToProperty.ready ) {
			if ( accessToProperty.write ) {
				return getControl()
			} else if ( accessToProperty.read ) {
				return <PlainValue value={ currentValue } hidden={ false }/>
			}
		}

		return null
	}

	return (
		<>
			{/*<p>isDomainEditable: {isDomainEditable?'YES':'NO'}, isPropertyWritable: {isPropertyWritable?'YES':'NO'}</p>*/}
			{ getElement() }
		</>
	)
}

TypeaheadControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	options: PropTypes.array,
};

export default TypeaheadControl;
