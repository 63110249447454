import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Col, Row, Form} from "react-bootstrap";
import {configuratorService} from "../_services/configurator.service";
import {useTranslation} from "react-i18next";
import DropdownControl from "../_components/DropdownControl";
import {useFormContext} from "react-hook-form";
import DefaultSpinner from "../_components/DefaultSpinner";

function ConfiguratorForm( { onChange, defaultValues, buildingId, brokerId, validFrom, setIsWorking } ) {
	const [configuratorValues, setConfiguratorValues] = useState( { } );
	const {configuratorOptions, mergedValues, ready} = configuratorService.useConfiguratorOptions( configuratorValues, defaultValues, validFrom, brokerId, buildingId );
	const [lastBuildingId, setLastBuildingId] = useState(buildingId);
	const {t} = useTranslation()
	const { getValues, reset } = useFormContext();

	setIsWorking(!ready)

	useEffect ( ()=> {
		let values = {}
		Object.keys(mergedValues).forEach( (key) => {
			const mergedValue = mergedValues[key];
			values[key] = String(mergedValue.value || '')
		})
		reset( values );
	}, [mergedValues, reset])

	useEffect( ()=> {
		if ( lastBuildingId !== buildingId ) {
			// reset({});
			// setValues({});
			setLastBuildingId(buildingId);
		}
		onChange(mergedValues);
	}, [buildingId, lastBuildingId, onChange, mergedValues, reset])

	const handleChange = useCallback( (e) => {
		const data = getValues()
		let result = {}
		let newData = {}
		let i=1;
		for( const val in data ) {
			if ( data[val] ) {
				result[val] = { value: data[val], order: i++ };
				newData[val] = data[val];
			}
			if ( e.target.name === val ) {
				//input has been changed - all following inputs may be irrelevant now - we skip them.
				break;
			}
		}

		setConfiguratorValues( result );
	}, [setConfiguratorValues, getValues]);

	const options = useMemo( () => {
		const options = configuratorOptions.map( (configuratorOption, index) => {
			const mergedValue = mergedValues[configuratorOption.className];
			return <Row key={configuratorOption.className} className={"mb-3"}>
				<Form.Group as={Col} md="12" controlId={'group' + configuratorOption.className}>
					<Form.Label>{t(configuratorOption.className + ".label")}</Form.Label>
					<DropdownControl
						nullable={true}
						name={configuratorOption.className}
						options={configuratorOption.options}
						keyPropName={'id'}
						valuePropName={'name'}
						value={ mergedValue.value }
						onChange={handleChange}
						readOnly={ mergedValue && mergedValue.isDefaultValue }
						rules={{
							required: true
						}}
						disabled={ !ready }
					/>
				</Form.Group>
			</Row>;
		});

		return options;
	}, [configuratorOptions, handleChange, t, mergedValues, ready]);

	return (
		<Row>
			{/*{JSON.stringify(getValues())}*/}
			{/*<Button onClick={ () => console.log(getValues()) }>getValues</Button>*/}
			<Col md={"12"}>
				<Card className={"mb-2"}>
					<Card.Header>{t('configurator.card.header')} <DefaultSpinner loading={!ready}/></Card.Header>
					<Card.Body>
						{options}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}

export { ConfiguratorForm };
