import React, {useCallback, useContext, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {Button, Card, Col, Row} from "react-bootstrap";
import TicketCommentEditor from "./TicketCommentEditor";
import TicketComment from "./TicketComment";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {TicketContext} from "./TicketContext";
import AllowedContextWrapper from "../_components/AllowedContextWrapper";

function TicketComments( { ticketId, previewOnly }) {
    const [ticketCommentEditingId, setTicketCommentEditingId] = useState(undefined);
    const {t} = useTranslation();
    const ticketContext = useContext(TicketContext);

    const ticketComments = ticketContext.ticketComments;
    const setTicketComments = ticketContext.setTicketComments;
    const refreshTicketComments = ticketContext.refreshTicketComments;

    const finishEdit = useCallback( (changedTicketComment) => {
        const removeNewTicketComment = () => {
            return ticketComments.filter( ( ticketComment ) => ticketComment.id !== undefined )
        }

        if ( changedTicketComment === undefined ) {
            setTicketComments( [...removeNewTicketComment()] )
        }
        else {
            const index = ticketComments.findIndex( ( ticketComment ) => ticketComment.id === changedTicketComment.id )
            if ( index !== -1 ) {
                ticketComments[index] = changedTicketComment
                setTicketComments( [...ticketComments] )
            } else {

                setTicketComments( [changedTicketComment, ...removeNewTicketComment()] )
            }
        }

        setTicketCommentEditingId( undefined )
    }, [ticketComments, setTicketComments])

    const onTicketCommentSubmit = useCallback( (data) => {
        const createNewTicketComment = (data) => {
            restService.saveDomainInstance( 'ticketComment', data )
                .then( (ticketComment) => {
                    finishEdit( ticketComment )
                })
                .catch( ( error ) => {
                    restService.handleServerErrorsAxios( error )
                } );
        }

        const updateTicketComment = (id, data) => {
            restService.updateDomainInstance( 'ticketComment', id, data )
                .then( (ticketComment) => {
                    finishEdit( ticketComment )
                })
                .catch( ( error ) => {
                    restService.handleServerErrorsAxios( error )
                } );
        }

        if ( data.id ) {
            updateTicketComment( data.id, data )
        }
        else {
            createNewTicketComment( data )
        }
    }, [finishEdit])

    const onTicketCommentCancel = useCallback( () => {
        finishEdit( undefined )
    }, [finishEdit] )

    const handleEditTicketComment = useCallback( (ticketCommentId) => {
        finishEdit( undefined )
        setTicketCommentEditingId(ticketCommentId)
    }, [finishEdit, setTicketCommentEditingId])

    const handleDeleteTicketComment = useCallback( (ticketCommentId) => {
        messageBoxService.display( t('ticketComments.delete.message.content'), t('ticketComments.delete.message.title'), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
            .then( (button) => {
                if ( button === MessageBoxButtons.YES ) {
                    restService.deleteDomainInstance( 'ticketComment', ticketCommentId )
                        .then( () => {
                            refreshTicketComments()
                        })
                        .catch( ( error ) => {
                            restService.handleServerErrorsAxios( error )
                        } );
                }
            })
    }, [t, refreshTicketComments])

    const ticketCommentsElement = useMemo( () => {
        const commentsElements = ticketComments?.map( (ticketComment) => {
            return (
                <Row key={ticketComment.id}>
                    <Col>
                        <AllowedContextWrapper domainName={'ticketComment'} id={ticketComment.id} allowShow={()=>`/api/ticketComment/${ticketComment.id}#GET`} allowEdits={()=>`/api/ticketComment/${ticketComment.id}/edit#GET`} allowDeletions={()=>`/api/ticketComment/${ticketComment.id}#DELETE`}>
                            {ticketComment.id === ticketCommentEditingId && !previewOnly ?
                                <TicketCommentEditor ticketComment={ticketComment} onSubmit={onTicketCommentSubmit} onCancel={onTicketCommentCancel}/>
                            :
                                <TicketComment ticketComment={ticketComment} onEdit={handleEditTicketComment} onDelete={handleDeleteTicketComment} previewOnly={previewOnly}/>
                            }
                        </AllowedContextWrapper>
                    </Col>
                </Row>
            )
        })

        return (
            <>
                <Row>
                    <Col>
                        {commentsElements}
                    </Col>
                </Row>
            </>
        )
    }, [ticketComments, ticketCommentEditingId, handleDeleteTicketComment, handleEditTicketComment, onTicketCommentCancel, onTicketCommentSubmit, previewOnly])

    const cardElement = useMemo( () => {
        return ( <Card>
            <Card.Header>
                {t('ticketComments.header')} ({ticketComments.length})
                <Button className={"float-end"} size={"sm"} type="button" variant={"primary"} onClick={ () => {
                    if ( !ticketComments.some( (ticketComment) => ticketComment.id === undefined ) ) {
                        setTicketComments( [{ ticket: { id: ticketId } }, ...ticketComments] )
                    }
                } }><FontAwesomeIcon icon={faPlus}/></Button>
            </Card.Header>
            <Card.Body>
                { ticketCommentsElement }
            </Card.Body>
        </Card> )
    }, [ticketComments, setTicketComments, ticketCommentsElement, ticketId, t])


	return previewOnly ? ticketCommentsElement : cardElement
}

export default TicketComments