import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {securityService} from "../_services/security.service";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import PlainValue from "./PlainValue";

function ReactQuillControl( { name, rules, validationMessages, value, placeholder, onChange, onBlur, disabled, className }) {
	const { control, getFieldState, setValue } = useFormContext();
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()
	const fieldState = getFieldState(name)

	useEffect(() => {
		if ( accessToProperty.ready && accessToProperty.write ) {
			setValue(name, value);
		}
	},[value, name, setValue, accessToProperty.ready, accessToProperty.write]);

	const getControl = () => {
		return (
			<>
				<Controller
					control={ control }
					name={ name }
					rules={ rules }
					defaultValue={ value }
					render={ ( { field, fieldState } ) => {
						let options = {
							onBlur: ( e ) => {
								field.onBlur( e );
								if ( onBlur ) {
									onBlur( e );
								}
							},
							onChange: ( val ) => {
								setValue( val );
								field.onChange( val );
								if ( onChange ) {
									onChange( val );
								}
							},
							defaultValue: value,
							// value: field.value,
							name: field.name,
							ref: field.ref,
							className: ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ),
							placeholder: placeholder,
							disabled: disabled,
							theme: "snow",
						}

						return <ReactQuill {...options}/>
					}}
				/>
				<Form.Control.Feedback type="invalid">
					{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
				</Form.Control.Feedback>
			</>
		)
	}

	if ( accessToProperty.ready ) {
		if ( accessToProperty.write ) {
			return getControl()
		} else if ( accessToProperty.read ) {
			return <PlainValue className={ "pe-3" } value={ value }/>
		}
	}

	return null
}

ReactQuillControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

export default ReactQuillControl;
