import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import { NumericFormat } from "react-number-format";
import {useTranslation} from "react-i18next";
import PlainValue from "./PlainValue";
import {securityService} from "../_services/security.service";

function FormControl( { type, name, rules, validationMessages, value, placeholder, onChange, onBlur, disabled, className, hidden, shouldUnregister, required = false, size, as, rows, step, mode="edit", thousandSeparator=false, autoComplete="on"}) {
	const { control, setValue } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

	const {t} = useTranslation()

	useEffect(() => {
		const newValue = defVal;
		setCurrentValue(newValue);
		if ( accessToProperty.ready && accessToProperty.write ) {
			setValue(name, newValue);
		}
	}, [value, name, defVal, setValue, accessToProperty.ready, accessToProperty.write]);

	const getControl = () => {
		return (
			<>
				{/*{<p>{value===undefined?'undefined':(value===null?'null':value)}</p>}*/ }
				<Controller
					control={ control }
					name={ name }
					rules={ rules }
					defaultValue={ currentValue }
					shouldUnregister={ shouldUnregister }
					render={ ( { field, fieldState } ) => {
						let options = {
							onBlur: ( e ) => {
								field.onBlur( e );
								if ( onBlur ) {
									onBlur( e );
								}
							},
							value: field.value,
							name: field.name,
							ref: field.ref,
							className: ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ),
							placeholder: placeholder,
							type: type,
							disabled: disabled,
							hidden: hidden,
							as: as,
							rows: rows,
							step: step,
							required: required,
							autoComplete: autoComplete,
						}
						if ( size ) {
							options['size'] = size;
						}
						if ( mode === "view" ) {
							options['plaintext'] = true;
							options['readOnly'] = true;
						}

						const getControl = () => {
							if ( thousandSeparator ) {
								options = {
									...options,
									...{
										customInput: Form.Control,
										type: "text",
										thousandSeparator: t( 'thousandSeparator' ),
										onChange: ( e ) => {
											setCurrentValue( e.target.value );
											field.onChange( e );
										},
										onValueChange: ( v ) => {
											if ( onChange ) {
												onChange( v.floatValue );
											}
										}
									}
								}
								delete options.ref
								return ( <NumericFormat
									{ ...options }
								/> )
							} else {
								options = {
									...options,
									...{
										onChange: ( e ) => {
											if ( type !== "date" || ( type === "date" && e.target.value ) ) { //when date 31.05.... was typed, then when the 0 was typed, the whole date disappeared, because e.target.value was empty string when 0 was typed.
												setCurrentValue( e.target.value );
												field.onChange( e );
												if ( onChange ) {
													onChange( e );
												}
											}
										},
									}
								}
								return ( <Form.Control
									{ ...options }
								/> )
							}
						}

						return (
							<>
								{ getControl() }
								<Form.Control.Feedback type="invalid">
									{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
								</Form.Control.Feedback>
							</>
						)
					}
					}
				/>
			</>
		)
	}

	if ( accessToProperty.ready ) {
		if ( accessToProperty.write ) {
			return getControl()
		} else if ( accessToProperty.read ) {
			return <PlainValue className={ "pe-3" } value={ value } hidden={ hidden }/>
		}
	}

	return null
	//return <p>{JSON.stringify(accessToProperty)}</p>
}

FormControl.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
	mode: PropTypes.oneOf(["view", "edit"]),
	thousandSeparator: PropTypes.bool,
	size: PropTypes.string,
	as: PropTypes.string,
	rows: PropTypes.number,
	step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shouldUnregister: PropTypes.bool,
};

export default FormControl;
