import React, {useEffect} from 'react'
import {Alert, TopMenuBar} from './_components';
import {Route, Switch, Redirect} from 'react-router-dom';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Footer from "./Footer";
import appService from "./_services";
import Announcement from "./_components/Announcement";
import Favicon from "react-favicon";
import settingService from "./_services/setting.service";

function AppRouter() {
	const routes = appService.getRoutes()
	const {_favicon} = settingService.useSetting( ['_favicon'] );
	const [faviconFile, setFaviconFile] = React.useState( require( './images/favicon.ico' ) );

	useEffect( () => {
		if ( _favicon && Object.keys( _favicon ).length > 0 ) {
			try {
				setFaviconFile( require( `./images/${ _favicon }` ) );
			}
			catch (e) {
				console.error( e );
			}
		}
	}, [_favicon] );


	const getAllRoutes = ( routes ) => {
		let result = [];
		routes.forEach((route) => {
			if ( route.routes ) {
				result = result.concat(getAllRoutes( route.routes ));
			}
			else {
				result.push( route );
			}
		})
		return result;
	}

	return (
		<>
			{ faviconFile && <Favicon url={faviconFile}/> }
			<div className="bg-light">
				<TopMenuBar />
			</div>

			<div>
				<Announcement/>
			</div>

			<div className="d-flex flex-grow-1">
				<div className="py-3 flex-grow-1">
					<Alert/>
					<Switch>
						{getAllRoutes(routes).map((route, index) => (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								render={routerProps => route.component(routerProps)}/>
						))}
						<Redirect from="*" to="/" />
					</Switch>
				</div>
			</div>

			<div className="bg-light p-2">
				<Footer/>
			</div>
		</>
	)
}

export default AppRouter;
