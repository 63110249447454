import React, {useEffect, useMemo} from 'react';
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PageTour from "../tour/PageTour";
import settingService from "../_services/setting.service";
import {faClipboardCheck, faEnvelope, faHome, faLanguage, faUser, faDoorOpen, faGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import useAuth from "../security/useAuth";
import FontAwesomeIconWithNumber from "./RoleNodeTree/FontAwesomeIconWithNumber";
import notificationService from "../_services/notification.service";
import {securityService} from "../_services/security.service";

function TopMenuBar(props) {
	const {logout} = useAuth()
	const {_header} = settingService.useSetting(['_header']);
	const {i18n, t} = useTranslation();
	const username = useSelector(state => state.auth.username)
	const history = useHistory()
	const unreadPlainMessagesCount = notificationService.useUnreadPlainMessagesCount()
	const todoTicketsCount = notificationService.useTodoTicketsCount()
	const {_logoAppvers} = settingService.useSetting( ['_logoAppvers'] );
	const [logoAppvers, setLogoAppvers] = React.useState( require('../images/logo_appvers_with_name.svg').default );
	const hasRoleTicketsAdmin = securityService.useGranted( 'ROLE_TICKETS_ADMIN' )
	const {_menuColor} = settingService.useSetting(['_menuColor']);

	useEffect( () => {
		if ( _logoAppvers && Object.keys( _logoAppvers ).length > 0 ) {
			try {
				const logo = require( `../images/${ _logoAppvers }` )
				setLogoAppvers( logo );
			}
			catch (e) {
				console.error( e );
			}
		}
	}, [_logoAppvers] );

	const todoTicketsCountTotal = useMemo( () => {
		return todoTicketsCount ? todoTicketsCount.reduce( (a,b) => a + b.count, 0 ) : 0
	}, [todoTicketsCount] )


	const todoTicketsBg = useMemo( () => {
		const highestPriorityRow = todoTicketsCount ? todoTicketsCount.reduce( (a, b) => {
			if ( a && a.sortOrder > b.sortOrder ) {
				return a
			}
			else {
				return b
			}
		}, null ) : undefined

		if ( highestPriorityRow ) {
			return notificationService.getTicketBg( highestPriorityRow.priority.name )
		}
	}, [todoTicketsCount] )

	useEffect( () => {
		document.title = `${(_header && _header.title) || 'AVS150'}`;
	}, [_header])

	const changeLanguage = (value) => {
		i18n.changeLanguage(value)
	}

	return (
		<>
			<Navbar bg="light" expand="sm">
				<Container fluid>
					<NavLink to={ "/" }>
						<Button className={ "vert-horz-centered w-100 appvers-style btn-home" } style={{color: _menuColor}}>
							<FontAwesomeIcon icon={ faHome } size={ 'lg' }/>
						</Button>
					</NavLink>
					<PageTour/>
					<div className="ms-auto">
						<Navbar.Toggle aria-controls="basic-navbar-nav"/>
					</div>
					<div className="w-100 d-flex justify-content-center">
						<Navbar.Brand className={ "ms-auto me-auto" } href={ "https://www.appvers.ch/home" }>
							{/*<p>_logoAppvers: { JSON.stringify( _logoAppvers ) }</p>*/}
							{/*<p>logoAppvers: { JSON.stringify( logoAppvers ) }</p>*/}
							<img src={ logoAppvers } alt={ "logo" }/>
						</Navbar.Brand>
					</div>
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav className="">
							<NavDropdown title={ <FontAwesomeIcon icon={ faLanguage }/> }>
								<NavDropdown.Item onClick={ () => changeLanguage( "en-CH" ) }>English</NavDropdown.Item>
								<NavDropdown.Item onClick={ () => changeLanguage( "de-CH" ) }>Deutsch</NavDropdown.Item>
							</NavDropdown>
							{ hasRoleTicketsAdmin.ready && hasRoleTicketsAdmin.value &&
							<Nav.Link href={ '/tickets' } target="_blank"
							          rel="noopener noreferrer"><FontAwesomeIconWithNumber icon={ faClipboardCheck }
							                                                               number={ todoTicketsCountTotal }
							                                                               bg={ todoTicketsBg }/></Nav.Link> }
							<Nav.Link href={ '/plainMessages' } target="_blank"
							          rel="noopener noreferrer"><FontAwesomeIconWithNumber icon={ faEnvelope }
							                                                               number={ unreadPlainMessagesCount }
							                                                               bg={ "info" }/></Nav.Link>
							<NavDropdown drop={ 'start' } title={ <FontAwesomeIcon icon={ faUser }/> }>
								<NavDropdown.Item onClick={ () => history.push( '/user/profile' ) }><FontAwesomeIcon
									icon={ faGear }/> { username }</NavDropdown.Item>
								<NavDropdown.Divider/>
								<NavDropdown.Item onClick={ () => logout( () => history.push( '/' ) ) }><FontAwesomeIcon
									icon={ faDoorOpen }/> { t( 'menuBar.logout.label' ) }</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export {TopMenuBar};
