import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";

function AclUserGroup({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t('aclUserGroup.id.label'),
			hidden: true
		},
		{
			dataField: 'name',
			text: t('aclUserGroup.name.label'),
		},
		{
			dataField: 'broker',
			text: t('aclUserGroup.broker.label'),
			formatter: (cell, row) => { return row.broker ? row.broker.label : '' }
		},
		{
			dataField: 'roleName',
			text: t('aclUserGroup.roleName.label'),
		}
	];

	return (
		<Index
			path={match.path}
			domainName={'aclUserGroup'}
			columns={columns}
			toString={ (object) => ( object.name ) }
			formElements={<FormElements/>}
			sortField={'name'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { AclUserGroup };
