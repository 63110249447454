import React, {useEffect, useState} from 'react';
import appService from "../_services";

function MergeHints({children, domain1, domain2}) {
	const [updatedChildren, setUpdatedChildren] = useState(children)

	useEffect( () => {
		setUpdatedChildren( appService.forEachReactChildren( children, (element) => {
			if ( element.props.__TYPE === 'MergeHint' ) {
				if ( domain1 && Object.keys( domain1 ).length > 0 && domain2 && Object.keys( domain2 ).length > 0 ) {
					return React.cloneElement( element, {
						domain1: domain1,
						domain2: domain2,
					} )
				}
				else {
					return undefined
				}
            }
			else {
				return element
			}
		} ) )

	}, [children, domain1, domain2] );


	return (
		<>
			{ updatedChildren }
		</>
	);
}



export default MergeHints
