import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {securityService} from "../_services/security.service";
import {AllowedForEnum} from "../form/form.service";

function SwitchButtonControl( { name, rules, validationMessages, value, onChange, disabled, onlabel, onstyle, offlabel, offstyle, size, style="w-100", width, height } ) {
	const { setValue, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);
	const isDomainEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )
	const isPropertyWritable = securityService.useAccessToPropertyOfAllowedContext( 'write' )
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

	useEffect(() => {
		const newValue = defVal;
		setCurrentValue(newValue);
		if ( accessToProperty.ready && accessToProperty.write ) {
			setValue(name, newValue);
		}
	}, [value, setValue, name, defVal, accessToProperty.ready, accessToProperty.write]);

	const isEditable = isDomainEditable.ready && isDomainEditable.value && isPropertyWritable.ready && isPropertyWritable.write && !disabled

	return (
		<span style={ { pointerEvents: `${ !isEditable ? 'none' : 'inherit' }` } }>
			{/*<p>currentValue: {currentValue === undefined ? 'undefined' : (currentValue === null ? 'null' : currentValue ? 'true' : 'false')}</p>*/ }
			{/*<p>value: {value === undefined ? 'undefined' : (value === null ? 'null' : value ? 'true' : 'false')}</p>*/ }
			{/*<p>defVal: {defVal === undefined ? 'undefined' : (defVal === null ? 'null' : defVal ? 'true' : 'false')}</p>*/ }
			<Controller
				control={ control }
				name={ name }
				rules={ rules }
				defaultValue={ currentValue }
				render={ ( { field, fieldState } ) => {
					const opts = {
						onChange: ( val ) => {
							setCurrentValue( val )
							field.onChange( val );
							if ( onChange ) {
								onChange( val );
							}
						},
						onBlur: field.onBlur,
						checked: currentValue,
						name: field.name,
						ref: field.ref,
						disabled: disabled,
						onlabel: onlabel,
						onstyle: onstyle,
						offlabel: offlabel,
						offstyle: offstyle,
						style: style,
						size: size,
						width: width,
						height: height,
					}
					return ( <>
						{/*<p>fieldValue: {field.value === undefined ? 'undefined' : (field.value === null ? 'null' : field.value ? 'true' : 'false')}</p>*/}
						<BootstrapSwitchButton
							{ ...opts }
						/>
						<Form.Control.Feedback type="invalid">
							{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
						</Form.Control.Feedback>
					</> )
				}
				}
			/>
		</span>
	)
}

SwitchButtonControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	onlabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
	onstyle: PropTypes.string,
	offlabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
	offstyle: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
};

export default SwitchButtonControl;
