import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import restService from "../_services/rest.service";
import {alertService} from "../_services";
import {withRouter} from "react-router-dom";
import {policyService} from "../_services/policy.service";
import SubmitButton from "../_components/SubmitButton";
import {apiClientPrivate} from "../api/apiClient";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import {unstable_batchedUpdates} from 'react-dom';

function CreatePolicyReview( props ) {
	const { t } = useTranslation()
	const useFormObject = useForm();
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [print, setPrint] = useState(false)

	const redirectToShow = useCallback( (id) => {
		props.history.push('/policyVersion/show/'+id)
		alertService.success( t( 'default.updated', { what: t( 'policy.label' ) } ), { keepAfterRouteChange: true } );
	}, [props.history, t])

	useEffect(() => {
		const redirectToShow = (id) => {
			props.history.push('/policyVersion/show/'+id)
			alertService.success( t( 'default.updated', { what: t( 'policy.label' ) } ), { keepAfterRouteChange: true } );
		}

		const save = () => {
			return new Promise( (resolve, reject) => {
				const params = { data: JSON.stringify( props.values ) }
				apiClientPrivate.post( `/api/policy/saveOrUpdateOffer`, params )
					.then( r => restService.handleServerResponseAxios( r ) )
					.then( json => resolve(json.policyVersionId) )
					.catch( error => {
						restService.handleServerErrorsAxios( error )
						reject( error )
				});
			});
		}

		if ( isSubmitting ) {
			save()
				.then( (id) => {
					if ( print ) {
						policyService.print(id)
							.then( () => {
								redirectToShow(id)
							} )
					}
					else {
						redirectToShow(id)
					}
				} )
			setIsSubmitting(false)  //this line was before in 'then' and 'catch' blocks, but it caused the form to be submitted twice
		}
	}, [isSubmitting, print, props.history, t, redirectToShow, props.values]);

	const handleClickPrevious = () => {
		props.goToNamedStep('lastTouches');
	}

	const isManualTariffOk = () => {
		//this function is here because, we try to catch a problem described in APPVERS-766
		//as soon as this problem is solved, we can remove this function
		return new Promise( (resolve, reject) => {
			const targetsWithManualTarif = props.values['detail'].targets.filter( (target) => target.isManualTariff )
			if ( targetsWithManualTarif.length > 0 ) {
				messageBoxService.display( <><p>In der Police existiert ein Produkt mit manuellem Tarif. { <ul> {targetsWithManualTarif.map(t => <li>{t.pathAndNameAsText}</li>)}</ul> }</p><p>Wollen Sie die Police trotzdem speichern?</p></>, "Manuelles Tarif", [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-warnung text-white h5'} )
					.then( (result) => {
						if ( result === MessageBoxButtons.YES ) {
							resolve()
						}
						else {
							reject()
						}
					} )}
			else {
				resolve()
			}
		} )
	}

	const handleSave = () => {
		isManualTariffOk()
			.then( () => {
				setIsSubmitting( true ) //causes redraw and calls useEffect depending on isSubmitting
			})
	}

	const handleSaveAndPrint = () => {
		isManualTariffOk()
			.then( () => {
				unstable_batchedUpdates(() => {
					setIsSubmitting(true) //causes redraw and calls useEffect depending on isSubmitting
					setPrint(true)
				})
			})
	}

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form>
					<Alert variant={"success"}>{t('createPolicy.review.success')}</Alert>
					<div className="create-policy-footer p-3">
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						<SubmitButton isSubmitting={isSubmitting} type={"button"} onClick={handleSave} className='btn btn-default me-1'>{t('default.save')}</SubmitButton>
						<SubmitButton isSubmitting={isSubmitting} type={"button"} onClick={handleSaveAndPrint} className='btn btn-default'>{t('default.saveAndPrint')}</SubmitButton>
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export default withRouter(CreatePolicyReview);
