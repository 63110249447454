import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import DynamicText from "../_components/DynamicText";
import textService from "../_services/text.service";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {securityService} from "../_services/security.service";
import {AllowedForEnum} from "../form/form.service";

function TicketComment( {ticketComment, onEdit, onDelete, previewOnly} ) {
    const {t} = useTranslation();
    const isTicketCommentEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )

	return (
        <div className={"border-bottom mb-2"}>
            <div className={"m-2"}>
                <DynamicText htmlContent = {ticketComment.comment} />
            </div>
            <div className={"float-start x-small mb-2"}>
                <div>
                    {t('ticketComment.created')} {textService.formatDateTime(moment(ticketComment.createdOn))} {ticketComment.createdBy}
                </div>
                <div>
                    {t('ticketComment.lastChanged')} {textService.formatDateTime(moment(ticketComment.lastChangedOn))} {ticketComment.lastChangedBy}
                </div>
            </div>
            { isTicketCommentEditable.ready && isTicketCommentEditable.value && !previewOnly && <div className={"float-end mb-2"} style={{display: "table-cell", textAlign: "right", width: "80px", verticalAlign: "bottom", paddingBottom:"5px"}} >
                <Button type={"button"} className={"ms"} style={{right: "0px"}} variant={ "primary" } size={ "sm" } onClick={() => onEdit(ticketComment.id)}>
                    <FontAwesomeIcon icon={ faPencil }/>
                </Button>
                <Button type={"button"} className={"ms-1"} style={{right: "0px"}} variant={ "danger" } size={ "sm" } onClick={() => onDelete(ticketComment.id)}>
                    <FontAwesomeIcon icon={ faTrashAlt }/>
                </Button>
            </div> }
            <div className={ "clearfix" }></div>
        </div>
	)
}

export default TicketComment