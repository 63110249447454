import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form, Modal} from "react-bootstrap";
import FormControl from "./FormControl";
import {FormProvider, useForm} from "react-hook-form";

function PromptDialog({show, onHide, onConfirm, promptDefault, btnConfirmText, title, promptQuestion}) {
    const {t} = useTranslation();
    const useFormObject = useForm(); // react-hook-form methods
    const { setFocus } = useFormObject; // Destructure setFocus from useForm methods

    const handleSubmit = (data) => {
         onConfirm(data.promptAnswer);
         onHide()
    }

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                // Set focus to the input field using its "name"
                setFocus("promptAnswer");
            }, 100); // Small delay to ensure the modal is fully rendered

            return () => clearTimeout(timer); // Cleanup timeout if the component unmounts
        }
    }, [show, setFocus]);

    return (
        <Modal show={show} onHide={onHide} animation={false}>
            {show && <FormProvider {...useFormObject}>
                <Form>
                <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                                <Form.Label>{promptQuestion}</Form.Label>
                                <FormControl
                                    name="promptAnswer"
                                    type="text"
                                    rules={{
                                        required: true
                                    }}
                                    autoFocus={show}
                                    disabled={!show}
                                    shouldUnregister={true}
                                    value={promptDefault}
                                />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type={"submit"} onClick={useFormObject.handleSubmit(handleSubmit)}>
                            {btnConfirmText}
                        </Button>
                        <Button variant="secondary" onClick={onHide}>
                            {t('default.cancel')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>}
        </Modal>
    )
}

export default PromptDialog
