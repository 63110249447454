import {useContext, useEffect, useMemo, useRef, useState} from "react";
import restService from "./rest.service";
import {AllowedContext, AllowedForEnum} from "../form/form.service";
import {cacheService} from "./cache.service";
import {SecuredType} from "../_enum/enum";
import {apiClient, apiClientPrivate} from "../api/apiClient";

export const securityService = {
	authenticateUser,
	validateOtp,
	hasAccess,
	hasRole,
	isGranted,
	useGranted,
	useAllowedFor,
	useClassSimpleNameOfAllowedContext,
	usePropertyNameOfAllowedContext,
	hasAccessToProperties,
	useAccessToProperty,
	useAccessToPropertyOfAllowedContext,
	useAccessToProperties,
	getAllowedContextValue,
	useRenderCount,
	passwordRecoveryRequest,
	checkToken,
	changePassword,
	updateProfile,
	setupTwoFactor,
	sendActivationLink,
	getPasswordRequirements,
	useConfiguratorNodeAccessIdentitiesList,
}

let hasAccessCache = cacheService.createSimpleCache()
let hasAccessToPropertiesCache = cacheService.createSimpleCache()
let hasRoleCache = cacheService.createSimpleCache()

function useConfiguratorNodeAccessIdentitiesList() {
	const [data, setData] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		setLoaded( false )
		apiClientPrivate.get( `/api/configurator/getConfiguratorNodeAccessIdentitiesList`, {
			signal: controller.signal
		} )
			.then( r => restService.handleServerResponseAxios( r ) )
			.then( json => {
				setData( json )
				setLoaded( true )
			} )
			.catch( restService.handleServerErrorsAxios )
		return function cleanup() {
			controller.abort();
		}
	}, []);

	return [data, loaded];
}

function getAllowedContextValue( data, allowShow, allowEdits, allowDeletions ) {
	return {
		[AllowedForEnum.SHOW]: allowShow,
		[AllowedForEnum.EDIT]: allowEdits,
		[AllowedForEnum.DELETE]: allowDeletions,
		data: data,
	}
}

function authenticateUser(loginUserDetails) {
	return apiClient.post('/api/login', loginUserDetails, {
			withCredentials: true
		})
		.then((response) => {
			let data = response.data
			return data
		})
}

function validateOtp(data) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.post(`/api/validateOtp`, data, {
			signal: controller.signal
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then((data) => {
				resolve(data)
			})
			.catch((error) => {
				restService.handleServerErrorsAxios(error)
			})
	})
}

function passwordRecoveryRequest(username, locale) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		const data = {
			username: username,
			locale: locale
		}
		apiClient.post(`/api/security/resetPasswordRequest`, data, {
			signal: controller.signal,
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then(() => {
				resolve(true)
			})
			.catch((error) => {
				restService.handleServerErrorsAxios(error)
			})
	})
}

function checkToken(token) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		return apiClient.post(`/api/security/checkToken`, {token: token}, {
			signal: controller.signal,
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then((data) => {
				resolve(data)
			})
			.catch( error => reject( error, controller.signal ) );
	})
}

function getPasswordRequirements(token) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		return apiClient.post(`/api/security/getPasswordRequirements`, {token: token}, {
			signal: controller.signal,
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then((data) => {
				resolve(data)
			})
			.catch( error => reject( error, controller.signal ) );
	})
}

function changePassword(token, password) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		const data = {
			token: token,
			password: password
		}
		apiClient.post(`/api/security/changePassword`, data, {
			signal: controller.signal,
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then(( data ) => {
				resolve( data )
			})
			.catch((error) => {
				restService.handleServerErrorsAxios(error)
			})
	})
}

function sendActivationLink(username, locale) {
	return new Promise((resolve, reject) => {
		const controller = new AbortController();
		const data = {
			username: username,
			locale: locale
		}
		apiClient.post(`/api/security/sendActivationLink`, data, {
			signal: controller.signal,
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then(() => {
				resolve(true)
			})
			.catch((error) => {
				restService.handleServerErrorsAxios(error)
			})
	})
}

function updateProfile(data) {
	return new Promise((resolve, reject) => {
		apiClientPrivate.post(`/api/user/updateProfile`, data)
			.then((response) => restService.handleServerResponseAxios(response))
			.then((data) => {
				resolve(data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

function setupTwoFactor(data) {
	return new Promise((resolve, reject) => {
		apiClientPrivate.post(`/api/user/setupTwoFactor`, data, {
			withCredentials: true
		})
			.then((response) => restService.handleServerResponseAxios(response))
			.then((data) => {
				resolve(data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

function hasAccess( urls, signal ) {
	return cacheService.cacheableFetch('/api/application/hasAccess', urls, 'urls', hasAccessCache, signal)
}

function hasRole( roles, signal ) {
	return cacheService.cacheableFetch('/api/application/hasRole', roles, 'roles', hasRoleCache, signal)
}

/**
 * Increments and logs the number of times a component rerenders.
 *
 * @param {Object} options - The options object.
 * @param {string} options.componentName - The name of the component.
 *
 * @example
 * useRenderCount({ componentName: 'MyComponent' });
 */
function useRenderCount(componentName) {
	const rendersNo = useRef(0);
	useEffect(() => {
		rendersNo.current++;
	});
	console.log(`${componentName} rerenders: ` + rendersNo.current)
}

function isGranted( granted, type = SecuredType.ALL ) {
	const isUrl = (value) => {
		return value.startsWith('/')
	}

	const isRole = (value) => {
		return value.startsWith('ROLE_')
	}

	let urls = []
	if ( typeof(granted) === 'string' ) {
		if ( isUrl(granted) ) {
			urls.push( granted )
		}
	}
	else if ( Array.isArray(granted) ) {
		urls = granted.filter( value => isUrl(value) )
	}
	else if ( granted === undefined ) {
		//just use an empty list
	}
	else {
		throw new Error( `Unknown granted type ${ typeof granted }` )
	}


	let roles = []
	if ( typeof(granted) === 'string' ) {
		if ( isRole(granted) ) {
			roles.push( granted )
		}
	}
	else if ( Array.isArray(granted) ) {
		roles = granted.filter( value => isRole(value) )
	}
	else if ( granted === undefined ) {
		//just use an empty list
	}
	else {
		throw new Error( `Unknown granted type ${ typeof granted }` )
	}

	const urlsPromise = new Promise( ( resolve, reject ) => {
		if ( urls && urls.length > 0 ) {
			securityService.hasAccess( urls )
				.then( ( value ) => {
					switch ( type ) {
						case SecuredType.ALL:
							const allUrlsAreAccessible = urls.reduce( ( acc, url ) => acc && value[url], true )
							resolve( allUrlsAreAccessible )
							break
						case SecuredType.ANY:
							const accessibleUrlExists = urls.reduce( ( acc, url ) => acc || value[url], false )
							resolve( accessibleUrlExists )
							break
						default:
							reject( `Unknown SecuredType ${ type }` )
					}
				} )
				.catch( reject )
		} else {
			resolve( true )
		}
	} )

	const rolesPromise = new Promise( ( resolve, reject ) => {
		if ( roles && roles.length > 0 ) {
			securityService.hasRole( roles )
				.then( ( value ) => {
					switch( type ) {
						case SecuredType.ALL:
							const allRolesAreGranted = roles.reduce( ( acc, role ) => acc && value[role], true )
							resolve( allRolesAreGranted )
							break
						case SecuredType.ANY:
							const grantedRoleExists = roles.reduce( ( acc, role ) => acc || value[role], false )
							resolve( grantedRoleExists )
							break
						default:
							reject( `Unknown SecuredType ${ type }` )
					}
				} )
				.catch( reject )
		}
		else {
			resolve( true )
		}
	})

	return new Promise( ( resolve, reject ) => {
		Promise.all( [urlsPromise, rolesPromise] )
			.then( ( [urlsAreAccessible, rolesAreGranted] ) => {
				resolve( urlsAreAccessible && rolesAreGranted )
			} )
			.catch( reject )
	})
}

/**
 * useGranted(granted, type): boolean
 *
 * Just one of the parameters can be set.
 *
 * @param granted {array|string} of urls or roles (or both)
 * @param [type=SecuredType.ALL] {string} SecuredType enum
 *
 * @return {Object} Returns true if the user has access to all/any of the given url(s) and/or role(s).
 */
function useGranted( granted, type = SecuredType.ALL ) {
	const [result, setResult] = useState( { ready: false } )

	useEffect( () => {
		const controller = new AbortController()

		securityService.isGranted( granted, type )
			.then( ( value ) => {
				if ( !controller.signal.aborted ) {
					setResult( { value: value, ready: true } )
				}
			} )
			.catch( () => {
				if ( !controller.signal.aborted ) {
					throw new Error( `Failed to check access to ${ granted }` )
				}
			} )

		return function cleanup() {
			controller.abort()
		}
	}, [granted, type] )

	return result
}

function useClassSimpleNameOfAllowedContext() {
	const allowedContext = useContext(AllowedContext);
	if ( allowedContext && allowedContext.classSimpleName ) {
		return allowedContext.classSimpleName
	}
	else if ( allowedContext && allowedContext.data && allowedContext.data.classSimpleName ) {
		return allowedContext.data.classSimpleName
	}
	else {
		// console.warn('classSimpleName is not set in AllowedContext', allowedContext)
	}
}

function usePropertyNameOfAllowedContext() {
	const allowedContext = useContext(AllowedContext);
	const classSimpleName = useClassSimpleNameOfAllowedContext()
	const propertyName = allowedContext && allowedContext.propertyName
	if ( classSimpleName && propertyName  ) {
		return classSimpleName + '.' + propertyName
	}
	else {
		return undefined
	}
}

function hasAccessToProperties( properties, signal ) {
	return cacheService.cacheableFetch('/api/application/hasAccessToProperties', properties, 'properties', hasAccessToPropertiesCache, signal)
}

function useAccessToProperties(properties) {
	const [hasAccess, setHasAccess] = useState( false );
	const [ready, setReady] = useState(false)

	const propertiesList = useMemo( () => {
		if ( typeof(properties) === 'string' ) {
			return [properties]
		}
		else if ( Array.isArray(properties) ) {
			return properties
		}
		else {
			return undefined
		}
	}, [properties])

	useEffect( () => {
		if ( propertiesList ) {
			const controller = new AbortController()
			securityService.hasAccessToProperties(propertiesList)
				.then(( result ) => {
					if (!controller.signal.aborted) {
						setHasAccess(result)
						setReady(true)
					}
				})
				.catch(( error ) => {
					if (!controller.signal.aborted) {
						throw new Error(`Failed to check access to ${propertiesList}`)
					}
				})

			return function cleanup() {
				controller.abort()
			}
		}
		else {
			setReady(true)
		}
	}, [ propertiesList, setHasAccess] )

	const fallbackResult = useMemo( () => {
		return { ready: false }
	}, [])

	let result
	if ( ready ) {
		result = {value: hasAccess, ready: ready}
	}
	else {
		result = fallbackResult
	}

	//console.log(`useAccessToProperties(${JSON.stringify(properties)})`, result)
	return result
}

function useAccessToPropertyOfAllowedContext( ) {
	const propertyName = usePropertyNameOfAllowedContext()
	return useAccessToProperty( propertyName )
}

function useAccessToProperty( propertyName ) {
	const accessToProperty = useAccessToProperties( propertyName )
	const allowedForEdit = securityService.useAllowedFor( AllowedForEnum.EDIT )
	const allowedForShow = securityService.useAllowedFor( AllowedForEnum.SHOW )
	const allowedForDelete = securityService.useAllowedFor( AllowedForEnum.DELETE )

	const fallbackResult = useMemo( () => {
		return { ready: false }
	}, [])

	let result

	if ( accessToProperty.ready && allowedForEdit.ready && allowedForShow.ready ) {
		if ( propertyName ) {
			const value = accessToProperty.value[propertyName]
			if ( !value ) {
				throw new Error( `Property ${propertyName} not found in accessToProperty ${JSON.stringify(accessToProperty)}` )
			}
			result = {
				read: value.read && allowedForShow.value,
				write: value.write && allowedForEdit.value,
				delete: value.write && allowedForDelete.value,
				debug: {propertyName: propertyName, accessToProperty: value, allowedForShow: allowedForShow, allowedForEdit: allowedForEdit},
				ready: true,
			}
		}
		else {
			result = {
				read: allowedForShow.value,
				write: allowedForEdit.value,
				delete: allowedForDelete.value,
				debug: {propertyName: propertyName, accessToProperty: null, allowedForShow: allowedForShow, allowedForEdit: allowedForEdit},
				ready: true,
			}
		}
	}
	else {
		result = fallbackResult
	}

	// console.log(`propertyName: ${propertyName}`, {
	// 	accessToProperty: accessToProperty,
	// 	allowedForEdit: allowedForEdit,
	// 	allowedForShow: allowedForShow,
	// 	result: result
	// })
	return result
}

/**
 * @param allowedFor {AllowedForEnum} one of AllowedForEnum
 * @returns {Object} returns true if the user is allowed for the given allowedFor action
 */
function useAllowedFor( allowedFor ) {
	const [allowed, setAllowed] = useState( false );
	const [ready, setReady] = useState( false );
	const allowedContext = useContext(AllowedContext);

	const [url, setUrl] = useState( undefined )
	const hasAccessToUrl = useGranted( url )

	useEffect( () => {
		if ( allowedContext ) {
			const allowData = allowedContext.data;
			const allowValue = allowedContext[allowedFor];

			switch ( typeof allowValue ) {
				case "undefined":
					setAllowed( true )
					setReady( true )
					break;
				case 'boolean':
					setAllowed( allowValue )
					setReady( true )
					break;
				case 'function':
					const allowResult = allowValue( allowData )
					switch ( typeof allowResult ) {
						case 'boolean':
							setAllowed( allowResult );
							setReady( true )
							break;
						case 'string':
							setUrl( allowResult )
							break;
						default:
							throw new Error( `Unknown allowResult type ${ typeof allowResult }` )
					}
					break;
				default:
					throw new Error( `Unknown allow type ${ typeof allowValue }: ${JSON.stringify(allowValue)}` )
			}
		}
		else {
			setAllowed( true )
			setReady( true )
		}
	}, [ allowedFor, allowedContext ] )

	const fallbackResult = useMemo( () => {
		return { ready: false }
	}, [])

	let result
	if ( ready || hasAccessToUrl.ready ) {
		result = { value: allowed || ( url && hasAccessToUrl.value ), ready: true }
	}
	else {
		result = fallbackResult
	}

	//console.log(`useAllowedFor ${allowedContext && allowedContext.propertyName}`, {typeOfAllowValue: allowedContext ? typeof allowedContext[allowedFor] : 'unknown', allowedFor: allowedFor, context: allowedContext, ready: ready, hasAccessToUrlReady: hasAccessToUrl.ready, result: result})
	return result
}
