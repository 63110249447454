import React from 'react';
import {Button, Form} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import ReactQuillControl from "../_components/ReactQuillControl";
import {FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCheck, faSquareXmark} from "@fortawesome/free-solid-svg-icons";

function TicketCommentEditor( {ticketComment, onSubmit, onCancel} ) {
	const useFormObject = useForm();

	return (
		<div className={"border-bottom mb-2"}>
				<FormProvider {...useFormObject}>
					<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
						<FormControl
							hidden={true}
							name={'id'}
							type="text"
							value={ ticketComment.id }
						/>
						<FormControl
							hidden={true}
							name={'ticket'}
							type="text"
							value={ {id: ticketComment.ticket.id} }
						/>
						<FormControl
							hidden={true}
							name={'version'}
							type="text"
							value={ ticketComment.version }
						/>
						<ReactQuillControl
							name={"comment"}
							value= { ticketComment.comment }
							rules={{
								required: true
							}}
						/>

						<div className={"float-end mt-2"} style={{display: "table-cell", textAlign: "right", width: "80px", verticalAlign: "bottom", paddingBottom:"5px"}} >
							<Button type={"submit"} className={"ms-1"} style={{right: "0px"}} variant={ "primary" } size={ "sm" }>
								<FontAwesomeIcon icon={ faSquareCheck }/>
							</Button>
							<Button type={"button"} className={"ms-1"} style={{right: "0px"}} variant={ "secondary" } size={ "sm" } onClick={onCancel}>
								<FontAwesomeIcon icon={ faSquareXmark }/>
							</Button>
						</div>
					</Form>
				</FormProvider>
			<div className={ "clearfix" }></div>
		</div>
	)
}

export default TicketCommentEditor