import {Dropdown} from "../_components/Dropdown";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {configuratorService} from "../_services/configurator.service";
import * as PropTypes from "prop-types";
import {
    Accordion,
    Button, ButtonGroup,
    Card,
    Col, Container,
    Form,
    Modal, Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import EditableTable, {validateTable} from "../form/EditableTable";
import {Alert} from "../_components";
import {Alert as BootstrapAlert} from "react-bootstrap"
import {useTranslation} from "react-i18next";
import PageTour from "../tour/PageTour";
import useTour from "../tour/useTour";
import {
    ConfiguratorTargetMethod,
    ConfiguratorTargetType,
    getEnumType,
    TariffUnit,
    BaseTargetNameType
} from "../_enum/enum";
import {Typeahead} from "react-bootstrap-typeahead";
import usePolicyTypes from "./usePolicyTypes";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import CustomPanelToggle from "../_components/CustomPanelToggle";
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import ConfiguratorNodeAccessList from "./ConfiguratorNodeAccess";

export default function ConfiguratorNodeEditor(props) {
    const firstConfiguratorNodeDomainHolder = useMemo( () => {
        if ( props.isTariff && props.configuratorNodeDomainHolders && props.configuratorNodeDomainHolders.length > 0 ) {
            return props.configuratorNodeDomainHolders[0];
        }
        else {
            return undefined;
        }
    }, [props.isTariff, props.configuratorNodeDomainHolders])

    const firstConfiguratorNodeDomainHolderId = useMemo( () => {
        let result = null;
        if ( firstConfiguratorNodeDomainHolder && firstConfiguratorNodeDomainHolder.domain.id ) {
            result = firstConfiguratorNodeDomainHolder.domain.id
        }
        return result
    }, [firstConfiguratorNodeDomainHolder])

    const firstConfiguratorNodeDomainHolderContent = useMemo( () => {
        let result = null;
        if ( firstConfiguratorNodeDomainHolder && firstConfiguratorNodeDomainHolder.domain.content ) {
            result = firstConfiguratorNodeDomainHolder.domain.content
        }
        return result
    }, [firstConfiguratorNodeDomainHolder])

    const defaultJsPathToRoot = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.jsPathToRoot || [];
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent])

    const defaultConfiguratorTargetStep = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.step || undefined;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent])

    const defaultConfiguratorTargetType = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.type || getEnumType('appvers.enums.ConfiguratorTargetType', ConfiguratorTargetType.VALUE);
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default true
    const defaultConfiguratorTargetUseStampTax = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.useStampTax !== undefined) ? firstConfiguratorNodeDomainHolderContent.useStampTax : true;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default false
    const defaultConfiguratorTargetIsAddressObligatory = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.isAddressObligatory !== undefined) ? firstConfiguratorNodeDomainHolderContent.isAddressObligatory : false;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )


    const defaultConfiguratorTargetFirstLossRisk = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.firstLossRisk !== undefined) ? firstConfiguratorNodeDomainHolderContent.firstLossRisk : false;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default true
    const defaultConfiguratorTargetIsBuildingObligatory = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.isBuildingObligatory !== undefined) ? firstConfiguratorNodeDomainHolderContent.isBuildingObligatory : false;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default true
    const defaultConfiguratorTargetHideAmountInPolicyPdf = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.hideAmountInPolicyPdf !== undefined) ? firstConfiguratorNodeDomainHolderContent.hideAmountInPolicyPdf : true;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default true
    const defaultConfiguratorTargetEsRelevant = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.esRelevant !== undefined) ? firstConfiguratorNodeDomainHolderContent.esRelevant : false;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    //Default false
    const defaultConfiguratorTargetUseLoeschfuenfer = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return (firstConfiguratorNodeDomainHolderContent.useLoeschfuenfer !== undefined) ? firstConfiguratorNodeDomainHolderContent.useLoeschfuenfer : false;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetRemark = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.remark || '';
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetDependsOn = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.dependsOn || '';
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetDependsOnText = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.dependsOnText || '';
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetValidFrom = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.validFrom || null;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetValidTo = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.validTo || null;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultAnsatzDependencyFactor = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return undefined === firstConfiguratorNodeDomainHolderContent.ansatzDependencyFactor ? '' : firstConfiguratorNodeDomainHolderContent.ansatzDependencyFactor;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultAmountDependencyFactor = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return undefined === firstConfiguratorNodeDomainHolderContent.amountDependencyFactor ? '' : firstConfiguratorNodeDomainHolderContent.amountDependencyFactor;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultRoundAmountToMultiple = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return undefined === firstConfiguratorNodeDomainHolderContent.roundAmountToMultiple ? '' : firstConfiguratorNodeDomainHolderContent.roundAmountToMultiple;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )


    const defaultConfiguratorTargetPolicyType = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.policyType;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] );

    const defaultConfiguratorTargetAvbDescription = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.avbDescription;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] );

    const defaultTargetText = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.text;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] );

    const defaultTariffs = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.tariffs;
        }
        else {
            return [];
        }
    }, [firstConfiguratorNodeDomainHolderContent] );

    const defaultTargetMethod = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.method || getEnumType("appvers.enums.ConfiguratorTargetMethod", ConfiguratorTargetMethod.INTERVALS);
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultTargetDiscountSurchargeNames = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.discountSurchargeNames;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultTargetDiscountSurchargeLabels = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.discountSurchargeLabels;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultTargetDiscountSurchargeValues = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.discountSurchargeValues;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultNameType = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.nameType ? firstConfiguratorNodeDomainHolderContent.nameType.name : BaseTargetNameType.DEFAULT;
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetStandardTexts = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.configuratorTargetStandardTexts ? firstConfiguratorNodeDomainHolderContent.configuratorTargetStandardTexts : [];
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const defaultConfiguratorTargetIndividualTexts = useMemo( () => {
        if ( firstConfiguratorNodeDomainHolderContent ) {
            return firstConfiguratorNodeDomainHolderContent.configuratorTargetIndividualTexts ? firstConfiguratorNodeDomainHolderContent.configuratorTargetIndividualTexts : [];
        }
        else {
            return undefined;
        }
    }, [firstConfiguratorNodeDomainHolderContent] )

    const getTariffTableData = () => {
        let tariffs = defaultTariffs;
        return tariffs.map((tariffData) => {
            return {
                id: tariffData.id,
                minInsuranceAmount: tariffData.minInsuranceAmount,
                maxInsuranceAmount: tariffData.maxInsuranceAmount,
                tariff: tariffData.tariff,
                tariffUnit: tariffData.tariffUnit,
                minPrice: tariffData.minPrice,
            }
        });
    }

    const {t} = useTranslation();
    const [configuratorNodeDomainHolders, setConfiguratorNodeDomainHolders] = useState( [...props.configuratorNodeDomainHolders] );
    const [showEdit, setShowEdit] = useState(props.showEdit);
    const [validated, setValidated] = useState(false);
    const [configuratorNodeClassNames] = configuratorService.useConfiguratorNodeClassNames(true, false);
    const [optionsDomainName, setOptionsDomainName] = useState(props.optionsDomainName);
    const [configuratorNodeAccessList, setConfiguratorNodeAccessList] = useState( props.configuratorNodeAccessList );
    const [configuratorTargetText, setConfiguratorTargetText] = useState( defaultTargetText );
    const [tariffData, setTariffData] = useState( getTariffTableData() );
    const [validationErrors, setValidationErrors] = useState(null);
    const [targetMethod, setTargetMethod] = useState( defaultTargetMethod );
    const [tariffsCount, setTariffsCount] = useState( defaultTariffs.length );
    const [configuratorTargetStep, setConfiguratorTargetStep] = useState ( defaultConfiguratorTargetStep )
    const [configuratorTargetRemark, setConfiguratorTargetRemark] = useState ( defaultConfiguratorTargetRemark )
    const [configuratorTargetDependsOn, setConfiguratorTargetDependsOn] = useState ( defaultConfiguratorTargetDependsOn )
    const [configuratorTargetAnsatzDependencyFactor, setConfiguratorTargetAnsatzDependencyFactor] = useState ( defaultAnsatzDependencyFactor )
    const [configuratorTargetAmountDependencyFactor, setConfiguratorTargetAmountDependencyFactor] = useState ( defaultAmountDependencyFactor )
    const [configuratorTargetRoundAmountToMultiple, setConfiguratorTargetRoundAmountToMultiple] = useState ( defaultRoundAmountToMultiple )
    const [configuratorTargetType, setConfiguratorTargetType] = useState ( defaultConfiguratorTargetType )
    const [configuratorTargetUseStampTax, setConfiguratorTargetUseStampTax] = useState( defaultConfiguratorTargetUseStampTax )
    const [configuratorTargetIsAddressObligatory, setConfiguratorTargetIsAddressObligatory] = useState( defaultConfiguratorTargetIsAddressObligatory )
    const [configuratorTargetFirstLossRisk, setConfiguratorTargetFirstLossRisk] = useState( defaultConfiguratorTargetFirstLossRisk )
    const [configuratorTargetIsBuildingObligatory, setConfiguratorTargetIsBuildingObligatory] = useState( defaultConfiguratorTargetIsBuildingObligatory )
    const [configuratorTargetHideAmountInPolicyPdf, setConfiguratorTargetHideAmountInPolicyPdf] = useState( defaultConfiguratorTargetHideAmountInPolicyPdf )
    const [configuratorTargetEsRelevant, setConfiguratorTargetEsRelevant] = useState( defaultConfiguratorTargetEsRelevant )
    const [configuratorTargetStandardTexts, setConfiguratorTargetStandardTexts] = useState( defaultConfiguratorTargetStandardTexts )
    const [configuratorTargetIndividualTexts, setConfiguratorTargetIndividualTexts] = useState( defaultConfiguratorTargetIndividualTexts )
    const [configuratorTargetUseLoeschfuenfer, setConfiguratorTargetUseLoeschfuenfer] = useState( defaultConfiguratorTargetUseLoeschfuenfer )
    const [configuratorTargetPolicyType, setConfiguratorTargetPolicyType] = useState ( defaultConfiguratorTargetPolicyType )
    const [configuratorTargetAvbDescription, setConfiguratorTargetAvbDescription] = useState ( defaultConfiguratorTargetAvbDescription )
    const [configuratorTargetDiscountSurchargeNames, setConfiguratorTargetDiscountSurchargeNames] = useState ( defaultTargetDiscountSurchargeNames )
    const [configuratorTargetDiscountSurchargeLabels, setConfiguratorTargetDiscountSurchargeLabels] = useState ( defaultTargetDiscountSurchargeLabels )
    const [configuratorTargetDiscountSurchargeValues, setConfiguratorTargetDiscountSurchargeValues] = useState ( defaultTargetDiscountSurchargeValues )
    const [nameType, setNameType] = useState( defaultNameType )
    const [validFrom, setValidFrom] = useState( defaultConfiguratorTargetValidFrom )
    const [validTo, setValidTo] = useState( defaultConfiguratorTargetValidTo )
    const [isPartOfTariffName, setIsPartOfTariffName] = useState( props.isPartOfTariffName )

    const [policyTypes] = usePolicyTypes()

    const configuratorNodeIds = useMemo( () => {
        var result = []
        for ( var n in defaultJsPathToRoot ) {
            const node = defaultJsPathToRoot[n];
            result.push(node.id);
        }
        return result;
    }, [defaultJsPathToRoot] )
    const availableStandardTextsHsNamedRestriction = useMemo( () => (
        {
            namedRestriction:
                {
                    queryName: "availableStandardTexts",
                    params:
                        {
                            configuratorNodeIds: configuratorNodeIds
                        }
                }
        }
    ), [configuratorNodeIds] );

    const [allStandardTexts] = restService.useDomainInstancesList('standardText', undefined, undefined, 'theme_sort', undefined, availableStandardTextsHsNamedRestriction, undefined, "select");
    const [allIndividualTexts] = restService.useDomainInstancesList('individualText', undefined, undefined, 'text_sort', undefined, undefined, undefined, "select");

    const columns = useMemo( () => [
        {id: "minInsuranceAmount", label: t("configuratorTargetTariff.minInsuranceAmount.label"), className: "tour-configurator-node-editor-tariffs-minInsuranceAmount", input: {tag: "input", type: "integer"}, validator: (value,data,rowIndex) => { return (value > data[rowIndex].maxInsuranceAmount)?"Max must be greater or equal Min.":undefined}},
        {id: "maxInsuranceAmount", label: t("configuratorTargetTariff.maxInsuranceAmount.label"), className: "tour-configurator-node-editor-tariffs-maxInsuranceAmount", input: {tag: "input", type: "integer"}, validator: (value,data,rowIndex) => { return (value < data[rowIndex].minInsuranceAmount)?"Min must be smaller or equal Max.":undefined}},
        {id: "tariff", label: t("configuratorTargetTariff.tariff.label"), className: "tour-configurator-node-editor-tariffs-tariff", input: {tag: "input", type: "double", required: true}},
        {id: "tariffUnit", label: t("configuratorTargetTariff.tariffUnit.label"), className: "tour-configurator-node-editor-tariffs-tariffUnit", input: {tag: "select", enumType: 'appvers.enums.TariffUnit', values: Object.values(TariffUnit).map( (unit) => ({id: unit, label: t('appvers.enums.TariffUnit.' + unit)})), defaultValue: TariffUnit.PERCENT}},
        {id: "minPrice", label: t("configuratorTargetTariff.minPrice.label"), className: "tour-configurator-node-editor-tariffs-minPrice", input: {tag: "input", type: "double"}}
    ], [t] )

    const configuratorTargetStandardTextsColumns = useMemo( () => [
        {id: "mandatory", label: t("configuratorTargetStandardText.mandatory.label"), className: "???", input: {tag: "check", type: "checkbox"}},
        {id: "standardText", label: t("configuratorTargetStandardText.standardText.label"), className: "???", input: {tag: "select", values: allStandardTexts, required: true, nullable: true, optionClassName: (row) => (row.active ? 'text-dark' : 'text-danger') }},
    ], [t, allStandardTexts] )

    const configuratorTargetIndividualTextsColumns = useMemo( () => [
        {id: "mandatory", label: t("configuratorTargetIndividualText.mandatory.label"), className: "???", input: {tag: "check", type: "checkbox"}},
        {id: "individualText", label: t("configuratorTargetIndividualText.individualText.label"), className: "???", input: {tag: "select", values: allIndividualTexts, required: true, nullable: true, optionClassName: (row) => (row.active ? 'text-dark' : 'text-danger') }, formatter: (object) => object.individualText.label },
    ], [t, allIndividualTexts] )

    const getTourSteps = () => {
        let steps = []
        if ( props.isTariff ) {
            switch( optionsDomainName ) {
                case 'configuratorTargetAdditionalProducts':
                case 'configuratorTargetAdditionalProductsBroker':
                    steps.push( { selector: ".tour-configurator-node-editor-additional-product", content: t( "tour.configurator-node-editor.additional-product" ) } );
                    break
                default:
            }

            steps.push( { selector: ".tour-configurator-node-editor-targetName", content: t( "tour.configurator-node-editor.targetName" ) } );

            steps.push( { selector: ".tour-configurator-node-editor-target-type", content: t( "tour.configurator-node-editor.type" ) } );

            columns.forEach( (column) => {
                steps.push( { selector: ".tour-configurator-node-editor-tariffs-" + column.id, content: t("tour.configurator-node-editor.tariffs-" + column.id) } );
            });

            if ( tariffsCount > 1 ) {
                let content = "";
                Object.values(ConfiguratorTargetMethod).forEach( (targetMethod) => {
                    content += ("<li><strong>" + t('appvers.enums.ConfiguratorTargetMethod.' + targetMethod) + "</strong><br/>" + t('appvers.enums.ConfiguratorTargetMethod.' + targetMethod + '.tooltip') + "</li>");
                });
                content = "<ul>" + content + "</ul>";
                steps.push( { selector: ".tour-configurator-node-editor-target-method", content: content } );
            }

            steps.push( { selector: ".tour-configurator-node-editor-remark", content: t( "tour.configurator-node-editor.remark" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-policyType", content: t( "tour.configurator-node-editor.policyType" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-avbDescription", content: t( "tour.configurator-node-editor.avbDescription" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-step", content: t( "tour.configurator-node-editor.step" ) } );

            steps.push( { selector: ".tour-configurator-node-editor-discountSurchargeNames", content: t( "tour.configurator-node-editor.discountSurchargeNames" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-discountSurchargeLabels", content: t( "tour.configurator-node-editor.discountSurchargeLabels" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-discountSurchargeValues", content: t( "tour.configurator-node-editor.discountSurchargeValues" ) } );
        }
        else {
            steps.push( { selector: ".tour-configurator-node-editor-options-domain-name", content: t( "tour.configurator-node-editor.options-domain-name" ) } );
            steps.push( { selector: ".tour-configurator-node-editor-node-domains-elements", content: t( "tour.configurator-node-editor.node-domains-elements" ) } );
        }
        return steps;
    }

    useTour(getTourSteps(), true);

    useEffect(() => {
        setShowEdit( props.showEdit );
        setConfiguratorNodeDomainHolders( props.configuratorNodeDomainHolders );
        setOptionsDomainName( props.optionsDomainName )
        setIsPartOfTariffName( props.isPartOfTariffName )
    }, [props.showEdit, props.configuratorNodeDomainHolders, props.optionsDomainName, props.isPartOfTariffName ]);

    const handleCancelEdit = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.handleCancelEdit();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        let errors = null
        if ( props.isTariff ) {
            errors = validateTable(tariffData, columns);
            setValidationErrors(errors)
        }

        if ( form.checkValidity() === false || errors ) {
            setValidated(true);  //zezelená to celý i když jsou tam chyby nalezené pomocí validateTable()
        }
        else {
            const data = {
                // id: props.dbId,
                // parentId: props.dbId,
                isPartOfTariffName: isPartOfTariffName,
                className: optionsDomainName,
                holders: configuratorNodeDomainHolders.filter( (holder) => holder.domain && holder.domain.className === optionsDomainName ).map((holder) => {
                    return {
                        id: holder.id,
                        domainId: holder.domain.id
                    }
                }),
                configuratorNodeAccessList: configuratorNodeAccessList,
            }
            if ( props.isTariff ) {
                let holder = data.holders[0];
                if ( tariffData ) {
                    holder.configuratorTargetTariffs = tariffData;
                }
                holder.text = configuratorTargetText;
                holder.method = targetMethod;
                holder.step = parseInt( configuratorTargetStep );
                holder.policyType = configuratorTargetPolicyType;
                holder.avbDescription = configuratorTargetAvbDescription;
                holder.remark = configuratorTargetRemark;
                holder.discountSurchargeNames = configuratorTargetDiscountSurchargeNames
                holder.discountSurchargeLabels = configuratorTargetDiscountSurchargeLabels
                holder.discountSurchargeValues = configuratorTargetDiscountSurchargeValues
                holder.nameType = nameType
                holder.type = configuratorTargetType;
                holder.useStampTax = configuratorTargetUseStampTax;
                holder.isAddressObligatory = configuratorTargetIsAddressObligatory;
                holder.firstLossRisk = configuratorTargetFirstLossRisk;
                holder.isBuildingObligatory = configuratorTargetIsBuildingObligatory;
                holder.hideAmountInPolicyPdf = configuratorTargetHideAmountInPolicyPdf;
                holder.esRelevant = configuratorTargetEsRelevant;
                holder.configuratorTargetStandardTexts = configuratorTargetStandardTexts;
                holder.configuratorTargetIndividualTexts = configuratorTargetIndividualTexts;
                holder.useLoeschfuenfer = configuratorTargetUseLoeschfuenfer;
                holder.dependsOn = configuratorTargetDependsOn;
                holder.ansatzDependencyFactor = configuratorTargetAnsatzDependencyFactor;
                holder.amountDependencyFactor = configuratorTargetAmountDependencyFactor;
                holder.roundAmountToMultiple = configuratorTargetRoundAmountToMultiple;
                holder.validFrom = validFrom;
                holder.validTo = validTo;
            }
            props.handleSubmit( data );
        }

    }

    const getConfiguratorNodeDomainsElements = () => {
        if (showEdit) {
            let lastIndex = 0
            const elements = configuratorNodeDomainHolders
                .filter( (configuratorNodeDomainHolder) => configuratorNodeDomainHolder.domain && optionsDomainName === configuratorNodeDomainHolder.domain.className)
                .map( ( configuratorNodeDomainHolder, index ) => {
                    console.log(configuratorNodeDomainHolder.name + ": " + (configuratorNodeDomainHolder.domain?configuratorNodeDomainHolder.domain.id:"null"))
                    lastIndex = index;
                    return ( <Dropdown
                        key={ index }
                        nullable={ true }
                        name={ configuratorNodeDomainHolder.name }
                        optionsDomainName={ optionsDomainName }
                        keyPropName={ 'id' }
                        valuePropName={ 'name' }
                        value={ configuratorNodeDomainHolder.domain.id }
                        onChange={event => handleConfigurationNodeDomainChange(event)}
                    /> )
                } )

            let maxNewIndex = 0
            configuratorNodeDomainHolders.forEach((configuratorNodeDomainHolder) => {
                const regex = /configuratorNodeDomainHolder-new-(\d*)/;
                const found = configuratorNodeDomainHolder.name.match(regex);
                if(found) {
                    const idx = parseInt(found[1])
                    if( maxNewIndex < idx ) {
                        maxNewIndex = idx;
                    }
                }
            })
            const newIndex = maxNewIndex + 1;
            elements.push(
                <Dropdown
                    key={ ++lastIndex }
                    nullable={ true }
                    name={ "configuratorNodeDomainHolder-new-" + newIndex }
                    optionsDomainName={ optionsDomainName }
                    keyPropName={ 'id' }
                    valuePropName={ 'name' }
                    value={ '' }
                    required={elements.length === 0}
                    onChange={event => handleConfigurationNodeDomainChange(event)}
                />
            )
            console.log("configuratorNodeDomainHolder-new-" + newIndex + ": null");
            return elements;
        }
        else {
            return undefined;
        }


    }

    const handleConfigurationNodeDomainChange = (event) => {
        const name = event.currentTarget.name;
        let holders
        let configuratorNodeDomainHolder = configuratorNodeDomainHolders.find(configuratorNodeDomainHolder => configuratorNodeDomainHolder.name === name);
        if( !configuratorNodeDomainHolder ) {
            configuratorNodeDomainHolder = {
                name: name
            }
            holders = [...configuratorNodeDomainHolders, configuratorNodeDomainHolder]
        }
        else {
            holders = [...configuratorNodeDomainHolders]
        }

        const value = event.currentTarget.value;
        if(value) {
            configuratorNodeDomainHolder.domain = { id: value, className: optionsDomainName, content: event.currentTarget[event.currentTarget.selectedIndex].text }
        }
        else {
            holders = holders.filter( (element) => element.name !== name );
        }
        setConfiguratorNodeDomainHolders(holders);
    }

    const getNonTariffBody = () => {
        const bootstrapSwitchButtonOptions = {
            name: 'isPartOfTariffName',
            onlabel: t("configuratorNodeEditor.isPartOfTariffName.onlabel"),
            offlabel: t("configuratorNodeEditor.isPartOfTariffName.offlabel"),
            checked: isPartOfTariffName,
            onChange: (value) => setIsPartOfTariffName(value),
            style: "mx-1 w-100" //When the property 'style' is used directly in <BootstrapSwitchButton style={some string}.../>, then React warns "Style prop value must be an object". When we use this bootstrapSwitchButtonOptions, then React don't warn any more... muahahaha!
        }
        return (
            <>
                <Row>
                    <Form.Group className={"tour-configurator-node-editor-options-domain-name"} as={Col} md="12" controlId={"className"}>
                        <Form.Label>{t("configuratorNodeEditor.className.label")}</Form.Label>
                        <Dropdown
                            nullable={true}
                            name={"className"}
                            options={configuratorNodeClassNames}
                            keyPropName={'id'}
                            valuePropName={'name'}
                            defaultValue={optionsDomainName}
                            required={true}
                            onChange={(event) => {
                                setOptionsDomainName(event.currentTarget.value);
                            }}
                        />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className={"tour-configurator-node-editor-node-domains-elements"} as={Col} md="12">
                        <Form.Label>{t("configuratorNodeEditor.configuratorNodeDomainsElement.label")}</Form.Label>
                        {getConfiguratorNodeDomainsElements()}
                    </Form.Group>
                </Row>

                <Row>
                    <Col as={Col} md="12">
                        <BootstrapSwitchButton {...bootstrapSwitchButtonOptions}/>
                    </Col>
                </Row>
            </>
        )
    }

    const onTariffDataChanged = useCallback( (data) => {
        setTariffData(data);
        setTariffsCount(data.filter( (row) => !row.deleted ).length);
    }, [])

    const tariffDataWarningElement = useMemo( () => {
        let lastMaxInsuranceAmount = undefined
        const gaps = []
        tariffData.forEach( (row) => {
            if ( row.minInsuranceAmount !== row.maxInsuranceAmount ) {
                if ( lastMaxInsuranceAmount && lastMaxInsuranceAmount+1 !== row.minInsuranceAmount ) {
                    gaps.push(`${lastMaxInsuranceAmount} and ${row.minInsuranceAmount}`)
                }
                lastMaxInsuranceAmount = row.maxInsuranceAmount
            }
        })

        if ( gaps.length === 0 ) {
            return undefined
        }
        else {
            return (
                <Row className={"mt-2"}>
                     <Col>
                        <BootstrapAlert variant="warning">{ t( "configuratorNodeEditor.tariffDataWarning.gaps", {gaps: gaps.join( ', ' ) } ) }</BootstrapAlert>
                     </Col>
                 </Row>
            )
        }
    }, [tariffData, t])

    const onConfiguratorTargetTextChange = useCallback( (e) => {
        setConfiguratorTargetText(e.target.value);
    }, [setConfiguratorTargetText])

    const onConfiguratorTargetStepChange = (e) => {
        setConfiguratorTargetStep(e.target.value);
    }

    const onConfiguratorTargetUseStampTaxChange = (e) => {
        setConfiguratorTargetUseStampTax(e.target.checked);
    }

    const onConfiguratorTargetIsAddressObligatoryChange = (e) => {
        if ( e.target.checked && configuratorTargetIsBuildingObligatory ) {
            setConfiguratorTargetIsBuildingObligatory(false)
        }
        setConfiguratorTargetIsAddressObligatory(e.target.checked);
    }

    const onConfiguratorTargetFirstLossRiskChange = (e) => {
        if ( e.target.checked && configuratorTargetFirstLossRisk ) {
            setConfiguratorTargetFirstLossRisk(false)
        }
        setConfiguratorTargetFirstLossRisk(e.target.checked);
    }

    const onConfiguratorTargetIsHideAmountInPolicyPdf = useCallback( (e) => {
        setConfiguratorTargetHideAmountInPolicyPdf(e.target.checked);
    }, [setConfiguratorTargetHideAmountInPolicyPdf]);

    const onConfiguratorTargetIsBuildingObligatoryChange = (e) => {
        if ( e.target.checked && configuratorTargetIsAddressObligatory ) {
            setConfiguratorTargetIsAddressObligatory(false)
        }
        setConfiguratorTargetIsBuildingObligatory(e.target.checked);
    }

    const onConfiguratorTargetEsRelevantChange = (e) => {
        setConfiguratorTargetEsRelevant(e.target.checked);
    }

    const onConfiguratorTargetStandardTextsChange = (value) => {
        setConfiguratorTargetStandardTexts(value)
    }

    const onConfiguratorTargetIndividualTextsChange = (value) => {
        setConfiguratorTargetIndividualTexts(value)
    }

    const onConfiguratorTargetUseLoeschfuenferChange = (e) => {
        setConfiguratorTargetUseLoeschfuenfer(e.target.checked);
    }

    const onConfiguratorTargetRemarkChange = (e) => {
        setConfiguratorTargetRemark(e.target.value);
    }

    const onConfiguratorTargetDependsOnChange = (e) => {
        setConfiguratorTargetDependsOn(e.target.value);
    }

    const onConfiguratorTargetAnsatzDependencyFactorChange = (e) => {
        setConfiguratorTargetAnsatzDependencyFactor(e.target.value);
    }

    const onConfiguratorTargetAmountDependencyFactorChange = (e) => {
        setConfiguratorTargetAmountDependencyFactor(e.target.value);
    }

    const onConfiguratorTargetRoundAmountToMultipleChange = (e) => {
        setConfiguratorTargetRoundAmountToMultiple(e.target.value);
    }

    const onConfiguratorTargetPolicyTypeChange = (sel) => {
        setConfiguratorTargetPolicyType(sel[0]);
    }

    const onConfiguratorTargetAvbDescriptionChange = (e) => {
        setConfiguratorTargetAvbDescription(e.target.value);
    }

    const onConfiguratorTargetPolicyTypeInputChange = (input) => {
        setConfiguratorTargetPolicyType(input);
    }

    const onConfiguratorTargetDiscountSurchargeNamesChange = (e) => {
        setConfiguratorTargetDiscountSurchargeNames(e.target.value);
    }

    const onConfiguratorTargetDiscountSurchargeLabelsChange = (e) => {
        setConfiguratorTargetDiscountSurchargeLabels(e.target.value);
    }

    const onConfiguratorTargetDiscountSurchargeValuesChange = (e) => {
        setConfiguratorTargetDiscountSurchargeValues(e.target.value);
    }

    const onNameTypeChange = useCallback( (e) => {
        setNameType(e.target.value)
    }, [setNameType]);

    const additionalProductNameInput = useMemo( () => {
       return <>
            <Row>
                <Form.Group className={"tour-configurator-node-editor-additional-product"} as={ Col } md="12" controlId="product">
                    <Form.Label>{t("configuratorNodeEditor.additionalProduct.label")}</Form.Label>
                    <Form.Control
                        name="product"
                        type="text"
                        value={ configuratorTargetText }
                        onChange={ onConfiguratorTargetTextChange }
                    />
                </Form.Group>
            </Row>
        </>
    }, [configuratorTargetText, onConfiguratorTargetTextChange, t])

    const brokerProductName = useMemo( () => {
        return <p>{configuratorTargetText}</p>
    }, [configuratorTargetText])

    const baseProductNameInput = useMemo( () => {
        return <>
                <ButtonGroup className={"tour-configurator-node-editor-targetName"}>
                    {Object.values(BaseTargetNameType).map((radio, idx) => (
                        <ToggleButton
                            key = { idx }
                            id={`radio-${radio}-${idx}`}
                            type = "radio"
                            variant = { nameType === radio ? "primary" : "outline-primary" }
                            name = { "targetName" }
                            value = { radio }
                            checked = { nameType === radio }
                            onChange = { onNameTypeChange }
                        >
                            {t('appvers.enums.BaseTargetNameType.' + radio)}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <BootstrapAlert className={"mt-1"} variant={"warning"}>
                    {t('appvers.enums.BaseTargetNameType.' + nameType + '.tooltip', {what: configuratorTargetText})}
                    { 'MANUAL' === nameType && <Form.Control
                         name="targetNameTooltip"
                         type="text"
                         value={ configuratorTargetText }
                         onChange={ onConfiguratorTargetTextChange }
                     />}
                </BootstrapAlert>
            </>
    }, [nameType, onNameTypeChange, configuratorTargetText, onConfiguratorTargetTextChange, t])

    const productNameInput = useMemo( () => {
        switch( optionsDomainName ) {
            case 'configuratorTargetAdditionalProducts':
            case 'configuratorTargetDependentProduct':
                return additionalProductNameInput;
            case 'configuratorTargetBase':
                return baseProductNameInput;
            case 'configuratorTargetBaseBroker':
            case 'configuratorTargetAdditionalProductsBroker':
            case 'configuratorTargetDependentProductBroker':
                return brokerProductName;
            default:
        }
    }, [optionsDomainName, additionalProductNameInput, baseProductNameInput, brokerProductName] )

    const handleOnChangeMethod = useCallback( (value) => {
        let m = getEnumType( 'appvers.enums.ConfiguratorTargetMethod', value );
        setTargetMethod( m );
    }, [setTargetMethod])

    const handleOnChangeType = useCallback( (value) => {
        let m = getEnumType( 'appvers.enums.ConfiguratorTargetType', value );
        setConfiguratorTargetType( m );
    }, [setConfiguratorTargetType])

    const methodToggleButtons = useMemo( () => {
        return Object.values(ConfiguratorTargetMethod).map( (m, idx) => {
            return <ToggleButton key={m} id={`radio-target-method-${idx}`} value={m}
                                 variant={targetMethod && targetMethod.name === m ? "primary" : "outline-primary"}>{t('appvers.enums.ConfiguratorTargetMethod.' + m)}</ToggleButton>
        })
    }, [targetMethod, t])

    const typeToggleButtons = useMemo(() => {
        return Object.values(ConfiguratorTargetType).map( (m, idx) => {
            return <ToggleButton key={m} id={`radio-target-type-${idx}`}  value={m} variant={ configuratorTargetType && configuratorTargetType.name === m ? "primary" : "outline-primary" }>{ t('appvers.enums.ConfiguratorTargetType.' + m) }</ToggleButton>
        })
    }, [configuratorTargetType, t])

    const dependsOnNamedCriteria = useMemo( () => {
        return {
            queryName: "dependsOnAvailableTargets",
                params: {exceptId: firstConfiguratorNodeDomainHolderId}
        }
    }, [firstConfiguratorNodeDomainHolderId])

    const tariffDependentProductElement = useMemo( () => {
        return (
            <>
                <Row>
                    { optionsDomainName === 'configuratorTargetDependentProduct' &&
                        <Form.Group className={"tour-configurator-node-editor-dependsOn"} as={Col} md="12" controlId="groupDependsOn">
                            <Form.Label>{t('configuratorNodeEditor.dependsOn.label')}</Form.Label>
                            <Dropdown
                                name={ "dependsOn" }
                                optionsDomainName={ "configuratorTarget" }
                                keyPropName={ 'id' }
                                valuePropName={ 'label' }
                                namedCriteria={ dependsOnNamedCriteria }
                                value={ configuratorTargetDependsOn }
                                onChange={ onConfiguratorTargetDependsOnChange }
                                required={true}
                                templateSuffix={'select'}
                            />
                        </Form.Group>
                    }
                    { optionsDomainName === 'configuratorTargetDependentProductBroker' &&
                        <ShowField label={'configuratorNodeEditor.dependsOn.label'} value={defaultConfiguratorTargetDependsOnText} type={'text'}/>
                    }
                </Row>

                <Row>
                    <Form.Group className={"tour-configurator-node-editor-ansatzDependencyFactor"} as={ Col } md="12" controlId="ansatzDependencyFactor">
                        <Form.Label>{t("configuratorNodeEditor.ansatzDependencyFactor.label")}</Form.Label>
                        <Form.Control
                            name="ansatzDependencyFactor"
                            type="number"
                            value={ configuratorTargetAnsatzDependencyFactor }
                            onChange={ onConfiguratorTargetAnsatzDependencyFactorChange }
                            required={true}
                        />
                        <Form.Control.Feedback type="invalid">
                            asdfasdf
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className={"tour-configurator-node-editor-amountDependencyFactor"} as={ Col } md="12" controlId="amountDependencyFactor">
                        <Form.Label>{t("configuratorNodeEditor.amountDependencyFactor.label")}</Form.Label>
                        <Form.Control
                            name="ansatzDependencyFactor"
                            type="number"
                            value={ configuratorTargetAmountDependencyFactor }
                            onChange={ onConfiguratorTargetAmountDependencyFactorChange }
                            required={true}
                        />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className={"tour-configurator-node-editor-roundAmountToMultiple"} as={ Col } md="12" controlId="roundAmountToMultiple">
                        <Form.Label>{t("configuratorNodeEditor.roundAmountToMultiple.label")}</Form.Label>
                        <Form.Control
                            name="roundAmountToMultiple"
                            type="number"
                            value={ configuratorTargetRoundAmountToMultiple }
                            onChange={ onConfiguratorTargetRoundAmountToMultipleChange }
                            required={true}
                        />
                    </Form.Group>
                </Row>
            </>
        )
    }, [optionsDomainName, t, dependsOnNamedCriteria, configuratorTargetDependsOn, defaultConfiguratorTargetDependsOnText, configuratorTargetAnsatzDependencyFactor, configuratorTargetAmountDependencyFactor, configuratorTargetRoundAmountToMultiple])

    const tariffDefaultElement = useMemo( () => {
        return (<>
            <Row className={"tour-configurator-node-editor-tariffs"}>
                <EditableTable columns={columns} data={tariffData} onChange={onTariffDataChanged}  validationErrors={ validationErrors }/>
            </Row>
            { ( tariffsCount > 1) &&
                <>
                    { tariffDataWarningElement }
                    <Row>
                        <Col>
                            <ToggleButtonGroup className={"tour-configurator-node-editor-target-method"} size="sm" name="language" type="radio" defaultValue={targetMethod && targetMethod.name} onChange={handleOnChangeMethod}>{methodToggleButtons}</ToggleButtonGroup>
                        </Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col>
                            <BootstrapAlert variant={"info mt-1"}>{ t( 'appvers.enums.ConfiguratorTargetMethod.' + (targetMethod && targetMethod.name) + '.tooltip' ) }</BootstrapAlert>
                        </Col>
                    </Row>
                </>
            }
        </>);
    }, [ columns, tariffData, onTariffDataChanged, validationErrors, tariffsCount, targetMethod, handleOnChangeMethod, methodToggleButtons, t])

    const amountOrCountDefaultElement = useMemo( () => {
        return (
            <Card className={"mt-2"}>
                <Card.Header>
                    {t("configuratorNodeEditor.amountOrCount.label")}
                </Card.Header>
                <Card.Body>
                    <Row>
                        <ToggleButtonGroup className={"tour-configurator-node-editor-target-type"} size="sm" name="type" type="radio" defaultValue={configuratorTargetType && configuratorTargetType.name} onChange={handleOnChangeType}>{typeToggleButtons}</ToggleButtonGroup>
                    </Row>
                    <Row className={"mt-3"}>
                        <Form.Group as={Col} md="12" controlId="hideAmountInPolicyPdf">
                            <Form.Check
                                type="switch"
                                id="hideAmountInPolicyPdf"
                                label={t('configuratorNodeEditor.hideAmountInPolicyPdf.label', {what: t("appvers.enums.ConfiguratorTargetType." + (configuratorTargetType && configuratorTargetType.name))})}
                                checked={configuratorTargetHideAmountInPolicyPdf}
                                onChange={ onConfiguratorTargetIsHideAmountInPolicyPdf }
                            />
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
        )
    }, [configuratorTargetHideAmountInPolicyPdf, onConfiguratorTargetIsHideAmountInPolicyPdf, configuratorTargetType, handleOnChangeType, typeToggleButtons, t]);

    const amountOrCountElement = useMemo( () => {
        switch( optionsDomainName ) {
            case 'configuratorTargetAdditionalProducts':
            case 'configuratorTargetBase':
            case 'configuratorTargetAdditionalProductsBroker':
            case 'configuratorTargetBaseBroker':
            case 'configuratorTargetDecInc':
            case 'configuratorTargetDependentProduct':
            case 'configuratorTargetDependentProductBroker':
                return amountOrCountDefaultElement;
            default:
        }
    }, [optionsDomainName, amountOrCountDefaultElement]);

    const tariffElement = useMemo( () => {
        switch( optionsDomainName ) {
            case 'configuratorTargetAdditionalProducts':
            case 'configuratorTargetBase':
            case 'configuratorTargetAdditionalProductsBroker':
            case 'configuratorTargetBaseBroker':
            case 'configuratorTargetDecInc':
                return tariffDefaultElement;
            case 'configuratorTargetDependentProduct':
            case 'configuratorTargetDependentProductBroker':
                return tariffDependentProductElement;
            default:
        }
    }, [optionsDomainName, tariffDefaultElement, tariffDependentProductElement])

    const getAdditionalProductsBody = () => {
        return (
            //todo: translate texts below
            <>
                { productNameInput &&
                    <Card>
                        <Card.Header>
                            Tariff name
                        </Card.Header>
                        <Card.Body>
                            { productNameInput }
                        </Card.Body>
                    </Card>
                }

                { amountOrCountElement }


                { tariffElement &&
                    <Card className={ "mt-2" }>
                        <Card.Header>
                            Tarife
                        </Card.Header>
                        <Card.Body>
                            { tariffElement }
                        </Card.Body>
                    </Card>
                }

                <Card className={"mt-2"}>
                    <Card.Header>
                        Sonstige
                    </Card.Header>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Form.Group className={"tour-configurator-node-editor-remark"} as={ Col } md="12" controlId="remark">
                                <Form.Label>{t("configuratorNodeEditor.remark.label")}</Form.Label>
                                <Form.Control
                                    name="remark"
                                    type="text"
                                    value={ configuratorTargetRemark }
                                    onChange={ onConfiguratorTargetRemarkChange }
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group className={"tour-configurator-node-editor-policyType"} as={ Col } md="12" controlId="policyType">
                                <Form.Label>{t("configuratorNodeEditor.policyType.label")}</Form.Label>
                                <Typeahead
                                    id={"policyType"}
                                    name="policyType"
                                    onChange={onConfiguratorTargetPolicyTypeChange}
                                    onInputChange={onConfiguratorTargetPolicyTypeInputChange}
                                    options={policyTypes}
                                    selected={configuratorTargetPolicyType ? [configuratorTargetPolicyType] : []}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group className={"tour-configurator-node-editor-avbDescription"} as={ Col } md="12" controlId="avbDescription">
                                <Form.Label>{t("configuratorNodeEditor.avbDescription.label")}</Form.Label>
                                <Dropdown
                                    nullable={true}
                                    required={true}
                                    name={ "avbDescription" }
                                    optionsDomainName={ "avbDescription" }
                                    keyPropName={ 'id' }
                                    valuePropName={ 'label' }
                                    value={ configuratorTargetAvbDescription }
                                    onChange={event => onConfiguratorTargetAvbDescriptionChange(event)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group className={"tour-configurator-node-editor-step"} as={ Col } md="12" controlId="step">
                                <Form.Label>{t("configuratorNodeEditor.step.label")}</Form.Label>
                                <Form.Control
                                    name="step"
                                    type="text"
                                    value={ configuratorTargetStep || "" }
                                    onChange={ onConfiguratorTargetStepChange }
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group as={Col} md="12" controlId="useStampTax">
                                <Form.Check
                                    type="switch"
                                    id="useStampTax"
                                    label={t('configuratorNodeEditor.useStampTax.label')}
                                    checked={configuratorTargetUseStampTax}
                                    onChange={ onConfiguratorTargetUseStampTaxChange }
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group as={Col} md="12" controlId="isAddressObligatory">
                                <Form.Check
                                    type="switch"
                                    id="isAddressObligatory"
                                    label={t('configuratorNodeEditor.isAddressObligatory.label')}
                                    checked={configuratorTargetIsAddressObligatory}
                                    onChange={ onConfiguratorTargetIsAddressObligatoryChange }
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group as={Col} md="12" controlId="isBuildingObligatory">
                                <Form.Check
                                    type="switch"
                                    id="isBuildingObligatory"
                                    label={t('configuratorNodeEditor.isBuildingObligatory.label')}
                                    checked={configuratorTargetIsBuildingObligatory}
                                    onChange={ onConfiguratorTargetIsBuildingObligatoryChange }
                                />
                            </Form.Group>
                        </Row>
                        {props.loeschfuenferRelevant &&
                            <Row className={"mb-3"}>
                                <Form.Group as={Col} md="12" controlId="useLoeschfuenfer">
                                    <Form.Check
                                        type="switch"
                                        id="useLoeschfuenfer"
                                        label={t('configuratorNodeEditor.useLoeschfuenfer.label')}
                                        checked={configuratorTargetUseLoeschfuenfer}
                                        onChange={onConfiguratorTargetUseLoeschfuenferChange}
                                    />
                                </Form.Group>
                            </Row>
                        }
                        {props.esRelevant &&
                            <Row className={"mb-3"}>
                                <Form.Group as={Col} md="12" controlId="esRelevant">
                                    <Form.Check
                                        type="switch"
                                        id="esRelevant"
                                        label={t('configuratorNodeEditor.esRelevant.label')}
                                        checked={configuratorTargetEsRelevant}
                                        onChange={onConfiguratorTargetEsRelevantChange}
                                    />
                                </Form.Group>
                            </Row>
                        }
                        <Row className={"mb-3"}>
                            <Form.Group as={Col} md="12" controlId="firstLossRisk">
                                <Form.Check
                                    type="switch"
                                    id="firstLossRisk"
                                    label={t('configuratorNodeEditor.firstLossRisk.label')}
                                    checked={configuratorTargetFirstLossRisk}
                                    onChange={ onConfiguratorTargetFirstLossRiskChange }
                                />
                            </Form.Group>
                        </Row>

                        <Card>
                            <Card.Header>
                                {t('configuratorNodeEditor.standardTexts.header')}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <EditableTable name={"configuratorTargetStandardTexts"} columns={configuratorTargetStandardTextsColumns} data={configuratorTargetStandardTexts || []} onChange={onConfiguratorTargetStandardTextsChange}/>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card className={"mt-3"}>
                            <Card.Header>
                                {t('configuratorNodeEditor.individualTexts.header')}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <EditableTable name={"configuratorTargetIndividualTexts"} columns={configuratorTargetIndividualTextsColumns} data={configuratorTargetIndividualTexts || []} onChange={onConfiguratorTargetIndividualTextsChange}/>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
                <Accordion defaultActiveKey={(configuratorTargetDiscountSurchargeNames || configuratorTargetDiscountSurchargeLabels || configuratorTargetDiscountSurchargeValues) ? "0" : ""}>
                    <Card className={"mb-2 mt-2"}>
                        <Card.Header>
                            <CustomPanelToggle as={Button} variant="link" eventKey="0">
                                {t("configuratorNodeEditor.discountSurcharge.card.header")}
                            </CustomPanelToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Row className={"mb-3"}>
                                    <Form.Group className={"tour-configurator-node-editor-discountSurchargeNames"} as={ Col } md="12" controlId="discountSurchargeNames">
                                        <Form.Label>{t("configuratorNodeEditor.discountSurcharge.name.label")}</Form.Label>
                                        <Form.Control
                                            name="discountSurchargeNames"
                                            type="text"
                                            value={ configuratorTargetDiscountSurchargeNames || "" }
                                            onChange={ onConfiguratorTargetDiscountSurchargeNamesChange }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className={"mb-3"}>
                                    <Form.Group className={"tour-configurator-node-editor-discountSurchargeLabels"} as={ Col } md="12" controlId="discountSurchargeLabels">
                                        <Form.Label>{t("configuratorNodeEditor.discountSurcharge.labels.label")}</Form.Label>
                                        <Form.Control
                                            name="discountSurchargeLabels"
                                            type="text"
                                            value={ configuratorTargetDiscountSurchargeLabels || "" }
                                            onChange={ onConfiguratorTargetDiscountSurchargeLabelsChange }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className={"tour-configurator-node-editor-discountSurchargeValues"} as={ Col } md="12" controlId="discountSurchargeValues">
                                        <Form.Label>{t("configuratorNodeEditor.discountSurcharge.values.label")}</Form.Label>
                                        <Form.Control
                                            name="discountSurchargeValues"
                                            type="text"
                                            value={ configuratorTargetDiscountSurchargeValues || "" }
                                            onChange={ onConfiguratorTargetDiscountSurchargeValuesChange }
                                        />
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            Gültigkeit
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group className={ "tour-configurator-node-editor-additional-validFrom" } as={ Col } md="6" controlId="validFrom">
                                    <Form.Label>{ t( "configuratorNodeEditor.validFrom.label" ) } </Form.Label>
                                    <Form.Control
                                        name="validFrom"
                                        type="date"
                                        value={ validFrom }
                                        onChange={ ( e ) => {setValidFrom( e.target.value ) } }
                                    />
                                </Form.Group>
                                <Form.Group className={ "tour-configurator-node-editor-additional-validTo" } as={ Col } md="6" controlId="validTo">
                                    <Form.Label>{ t( "configuratorNodeEditor.validTo.label" ) }</Form.Label>
                                    <Form.Control
                                        name="validTo"
                                        type="date"
                                        value={ validTo }
                                        onChange={ ( e ) => {
                                            setValidTo( e.target.value )
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                </Accordion>
            </>
        )
    }

    const getTariffBody = () => {
        return getAdditionalProductsBody();
    }

    const getModalBody = () => {
        if ( props.isTariff ) {
            return getTariffBody();
        }
        else {
            return getNonTariffBody();
        }
    }

    return (
        <>
            {/*Edit modal*/}
            <Modal
                show={showEdit}
                size="xl"
                animation={false}
                onHide={handleCancelEdit}
                centered
                backdrop="static"
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.isNewNode ? t('default.create') : t('default.edit')}</Modal.Title>
                        <PageTour/>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert id={'editorErrors'} />
                        {getModalBody()}
                        <Card className={"mt-2"}>
                            <Card.Header>
                                {t("configuratorNodeEditor.configuratorNodeAccessList.label")}
                            </Card.Header>
                            <Card.Body>
                                <Row className={"mt-3"}>
                                    <ConfiguratorNodeAccessList configuratorNodeAccessList={props.configuratorNodeAccessList} onChange={setConfiguratorNodeAccessList} />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className={"d-flex justify-content-start"}>
                        <Container>
                            <Row>
                                <Col sm={12} className={"col-sm-10 d-flex justify-content-end"}>
                                    <Button className={""} variant="primary" type="submit">
                                        {t('default.save')}
                                    </Button>
                                    <Button className={"ms-2"} variant="secondary" onClick={handleCancelEdit}>
                                        {t('default.close')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

ConfiguratorNodeEditor.propTypes = {
    showEdit: PropTypes.bool,
    configuratorNodeDomainHolders: PropTypes.array,
    handleSubmit: PropTypes.func,
    optionsDomainName: PropTypes.string,
};
