import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {useFormContext} from "react-hook-form";
import {UploadWithProgress} from "./UploadWithProgress";
import {Alert} from "./Alert";
import {alertService} from "../_services";
import {useTranslation} from "react-i18next";
import {UploadStatus} from "../_enum/enum";
import {useConfig} from "../_services/useConfig";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    backgroundColor: '#DEEBFF',
    borderColor: '#2484FF'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

/**
 *
 * @param changesPropertyName name of the property in the form object where the changes will be stored
 * @param maxFiles maximum number of files that can be uploaded. If undefined, multiple files can be uploaded
 * @param additionalFileProperties additional properties that will be added to the file object in the changes object
 * @param onReadyForSubmitChange callback which is executed when readyForSubmit is change. Sending readyForSubmit as parameter
 * @param accept file types that are accepted
 * @returns {JSX.Element}
 * @constructor
 */
export default function FileUploader({changesPropertyName, maxFiles, additionalFileProperties, onReadyForSubmitChange, accept}) {
    const [dropList, setDropList] = useState([]);
    const {getValues, setValue} = useFormContext();
    const {t} = useTranslation()
    const [alertOptions] = useState({id: 'uploadErrors'})
    const { config: { uploadInfo, uploadAllowedExtensions, uploadAllowedMimeTypes } } = useConfig()
    const MAX_FILE_SIZE_MB = uploadInfo.maxFileSize / (1024 * 1024)

    useEffect( () => {
        return () => {
            setValue(changesPropertyName, undefined)
        }
    }, [changesPropertyName, setValue])

    const generateFileId = (file) => {
        return `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    }

    const onDrop = useCallback( (acceptedFiles) => {
            setDropList( (prev) => {
                let newState = [ ...prev]
                //check if file is already in the list
                acceptedFiles.forEach( (file) => {
                    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                        alertService.error(t("fileUploader.error.sizeExceeded", { maxSize: MAX_FILE_SIZE_MB }), alertOptions);
                        return;
                    }
                    const fileExtension = file.name.split(".").pop().toLowerCase();
                    const isValidMimeType = uploadAllowedMimeTypes.includes(file.type);
                    const isValidExtension = uploadAllowedExtensions.includes(fileExtension);

                    if (!isValidMimeType || !isValidExtension) {
                        alertService.error(t("fileUploader.error.invalidFileType", { fileName: file.name, allowedExtensions: uploadAllowedExtensions.join(", ") }), alertOptions);
                        return;
                    }

                    let fileId = generateFileId(file)
                    if ( !newState.find( (drop) => drop.id === fileId ) ) {
                        newState.push({
                            file: file,
                            id: fileId,
                            status: UploadStatus.WAITING
                        })
                    }
                    else {
                        alertService.error(t("fileUploader.file.already.in.list", {fileName: file.name}), alertOptions)
                    }
                })
                return newState
            } )
    }, [alertOptions, t])

    const handleUploadDelete = useCallback( (dropLIstId) => {
        setDropList( prevState => {
            let newState = [...prevState]
            return newState.filter( (drop) => drop.id !== dropLIstId )
        })
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        autoFocus: true,
        onDrop: onDrop,
        noDragEventsBubbling: true,
        disabled: dropList.length >= maxFiles,
        multiple: maxFiles === undefined ? true : maxFiles > 1,
        accept: accept,
    });


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleUploadStatusChange = useCallback((fileId, status, data) => {
        let fileChanges = getValues(changesPropertyName);
        if ( !fileChanges ) {
            fileChanges = {};
        }
        if ( status === UploadStatus.REMOVED ) {
            delete fileChanges[fileId];
            handleUploadDelete(fileId)
        }
        else {
            if ( status === UploadStatus.DONE ) {
                let fileInfo = data.fileInfo;
                fileChanges[fileId] = {...{action: 'create'}, ...{fileInfo: fileInfo}, ...{properties: (additionalFileProperties || {})}};
            }
        }
        setValue(changesPropertyName, fileChanges )
        let nrOfDone = Object.keys(fileChanges).length
        if ( onReadyForSubmitChange ) onReadyForSubmitChange(nrOfDone  === dropList.length && nrOfDone > 0)
    }, [handleUploadDelete, changesPropertyName, setValue, additionalFileProperties, getValues, dropList.length, onReadyForSubmitChange])

    const renderUploads = () => {
        return dropList.map( (drop) => {
            return <UploadWithProgress  key={drop.id} file={drop.file} fileId={drop.id} onUploadStatusChange={handleUploadStatusChange} alertOptions={alertOptions}/>
        } )
    }

    const acceptExtensions = useMemo( () => {
        return accept ? Object.values(accept).map( (ext) => `*${ext}`).join(",") : undefined
    }, [accept])

    return (
        <>
            <div className="container">
                <div { ...getRootProps( { style } ) }>
                    <input { ...getInputProps() } />
                    <FontAwesomeIcon className={"mt-2"} icon={ faUpload }/>
                    <p className={"mt-3 text-center"}>{t('fileUploader.tooltip')}</p>
                    {acceptExtensions && <em>{t('fileUploader.tooltip.accept', {accept: acceptExtensions})}</em>}
                </div>
                <aside>
                    { dropList.length > 0 &&
                        <div className={ "fw-bold mt-3 mb-1" }>{ t( 'fileUploader.files.to.be.added')}</div>}
                    <Alert id={alertOptions.id} />
                    {renderUploads()}
                </aside>
            </div>
        </>
    )
}
