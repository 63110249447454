import React from 'react';
import {Badge} from "react-bootstrap";
import PaymentStatusesStats from "./PaymentStatusesStats";
import {useTranslation} from "react-i18next";

function PaymentStats({nrOfPairedInvoices, nrOfPayments, paymentStatesStats}) {
    const {t} = useTranslation();

    let bgClass = 'success';
    if ( nrOfPairedInvoices !== nrOfPayments ) {
        bgClass = 'warning';
    }

    return (
        <>
            {t('bankToCustomerNotification.nrOfPairedPayments')} <Badge bg={bgClass}>{nrOfPairedInvoices} / {nrOfPayments} {nrOfPairedInvoices < nrOfPayments ? ` (${nrOfPayments - nrOfPairedInvoices} ${t('bankToCustomerNotification.notYetPaired')})`  : <></> }</Badge>
            <PaymentStatusesStats paymentStatesStats={paymentStatesStats} horizontal={false}/>
        </>
    )
}

export default PaymentStats
