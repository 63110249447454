import {Form, FormLabel} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";
import {securityService} from "../_services/security.service";

function FormCheckControl( { type, name, rules, validationMessages, value, label, onChange, onBlur, disabled, className }) {
    const { register, getFieldState, setValue } = useFormContext();
    const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()
    const fieldState = getFieldState(name)

    useEffect(() => {
        if ( accessToProperty.ready && accessToProperty.write ) {
            setValue(name, value);
        }
    },[name, value, setValue, accessToProperty.ready, accessToProperty.write]);

    const getControl = () => {
        return (
            <>
                <Form.Check {...register(name, {
                    shouldUnregister: true,
                    value: value,
                    onChange: onChange,
                    rules: rules,
                    onBlur: onBlur,
                })} type={type} checked={value} label={label} disabled={ disabled } className={ ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ) }/>
                <Form.Control.Feedback type="invalid">
                    { reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
                </Form.Control.Feedback>
            </>
        )
    }

    if ( accessToProperty.ready ) {
        if ( accessToProperty.write ) {
            return getControl()
        } else if ( accessToProperty.read ) {
            if ( value ) {
                return <div>
                    <FontAwesomeIcon className={ "mt-auto mb-auto pe-2" } icon={ faSquareCheck }/>
                    <FormLabel className={"mb-auto"}>{ label }</FormLabel>
                </div>
            } else {
                return <div>
                    <FontAwesomeIcon className={ "mt-auto mb-auto pe-2" } icon={ faSquare }/>
                    <FormLabel className={"mb-auto"}>{ label }</FormLabel>
                </div>
            }
        }
    }

    return null
}

FormCheckControl.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    validationMessages: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
};

export default FormCheckControl;
