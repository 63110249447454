import React from 'react';
import EditableTable from "../form/EditableTable";
import {useTranslation} from "react-i18next";
import {ConfiguratorNodeAccessType, getEnumType} from "../_enum/enum";
import DefaultSpinner from "../_components/DefaultSpinner";
import {securityService} from "../_services/security.service";

function ConfiguratorNodeAccess({configuratorNodeAccessList, onChange}) {
    const [identities,loaded] = securityService.useConfiguratorNodeAccessIdentitiesList()
	const {t} = useTranslation()

	const columns = [
		{id: "identity", label: t('configuratorNodeAccess.identity.label'), input: {tag: "select", values: identities, required: true, nullable:true }, formatter: (identityInfo) => {
			return 'ahoj'
			}},
		{id: "type", label: t('configuratorNodeAccess.type.label'), input: {tag: "select", values: Object.values(ConfiguratorNodeAccessType).map( ( type) => {
			return {
				...getEnumType('appvers.configurator.ConfiguratorNodeAccessType', type),
				id: type,
				label: t('appvers.enums.ConfiguratorNodeAccessType.' + type)
			}}), required: true, nullable:true }, formatter: (row) => row.type},
	]

	return (
		loaded ?
			<>
				<EditableTable
					name="configuratorNodeAccess"
					columns={ columns }
					data={ configuratorNodeAccessList }
					onChange={ onChange }
				/>
			</>
			:
			<DefaultSpinner loading={!loaded}/>
	)
}

export default ConfiguratorNodeAccess