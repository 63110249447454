import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as DamageEventFormElements} from "../damageEvent/FormElements";
import {PolicyVersionStatus} from "../_enum/enum";
import restService from "../_services/rest.service";
import moment from "moment";
import {useFormContext} from "react-hook-form";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();
	const [ dateOfDamage, setDateOfDamage ] = useState( undefined )
	const {watch} = useFormContext()
	const policyVersionValue = watch('policyVersion')
	const [ policyVersion ] = restService.useDomainInstance( 'policyVersion', policyVersionValue && policyVersionValue.id )

	const searchOptionsNotInProcess = useMemo( () => {
		return { queryStrings: PolicyVersionStatus.isNotInProcessQueryString() }
	}, [])

	useEffect( () => {
		if ( domainInstance && domainInstance.dateOfDamage ) {
			setDateOfDamage( domainInstance.dateOfDamage )
		}
	}, [domainInstance])

	const dateOfDamageWarnings = useMemo( () => {
		const result = []

		if ( dateOfDamage && ( domainInstance.dateOfDamage !== dateOfDamage || domainInstance.policyVersion.id !== policyVersionValue?.id ) ) {
			// Soft constraints:
			// dateOfDamage shouldn't be older than 2 years
			const dateOfDamageDate = moment(dateOfDamage)
			const twoYearsAgo = moment().subtract(2, 'years')
			if ( dateOfDamageDate < twoYearsAgo ) {
				result.push( {type: 'soft', key: 'tooOld2years', text: t('damage.dateOfDamage.tooOld'), variant: 'danger' } )
			}
			else {
				// dateOfDamage shouldn't be older than 2 months
				const twoMonthsAgo = moment().subtract(2, 'months')
				if ( dateOfDamageDate < twoMonthsAgo ) {
					result.push( {type: 'soft', key: 'tooOld2months', text: t('damage.dateOfDamage.tooOld'), variant: 'warning' } )
				}
			}

			// Hard constraints:
			// Date of damage can't be in the future
			if ( dateOfDamageDate > moment() ) {
				result.push( {type: 'hard', key: 'inTheFuture', text: t('damage.dateOfDamage.inTheFuture'), variant: 'danger' } )
			}

			// Date of damage must be within the validity of the policy version
			if ( policyVersion && policyVersion.validFrom && policyVersion.validTo && ( dateOfDamageDate < moment(policyVersion.validFrom) || moment(policyVersion.validTo) < dateOfDamageDate ) ) {
				result.push( {type: 'hard', key: 'outOfPolicyVersionValidity', text: t('damage.dateOfDamage.outOfPolicyVersionValidity'), variant: 'danger' } )
			}
		}

		return result
	}, [dateOfDamage, policyVersion, domainInstance.dateOfDamage, domainInstance.policyVersion.id, t] )

	const dateOfDamageWarningsElement = useMemo( () => {
		if ( dateOfDamageWarnings.length > 0 ) {
			return <Row className={"mt-2"}>
				<Col>
					{ dateOfDamageWarnings.map( (warning) => <Alert key={warning.key} variant={warning.variant}>{warning.text}</Alert> )}
				</Col>
			</Row>
		}
		else {
			return <></>
		}

	}, [dateOfDamageWarnings])

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('damage.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamageDate">
							<Form.Label>{t('damage.dateOfDamage')}</Form.Label>
							<FormControl
								name={"dateOfDamage"}
								type={"date"}
								value={dateOfDamage}
								rules={{
									required: true,
									validate: () => {
										return dateOfDamageWarnings.filter( (warning) => warning.type === 'hard' ).length === 0
									}
								}}
								onChange={ (e) => setDateOfDamage(e.target.value) }
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupDamageDescription">
							<Form.Label>{t('damage.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value={domainInstance.description || (defaultValues && defaultValues.name)}
							/>
						</Form.Group>
						{ dateOfDamageWarningsElement }
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamagePolicyNumber">
							<Form.Label>{t('damage.policyNumber')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"policyVersion"}
								sort={"[Long]selectionSort"}
								value={domainInstance && domainInstance.policyVersion && domainInstance.policyVersion.id}
								label={domainInstance && domainInstance.policyVersion && domainInstance.policyVersion.label}
								searchOptions={ searchOptionsNotInProcess }
								name={'policyVersion'}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupDamageDamageEvent">
							<Form.Label>{t('damage.damageEvent')}</Form.Label>
							<RestAsyncSelectControl
								noSelection={{label: t('damage.damageEvent.noSelection'), id: ""}}
								placeholder={t('damage.damageEvent.noSelection')}
								domainName={"damageEvent"}
								sort={"eventDateTime desc"}
								value={ domainInstance && domainInstance.damageEvent && domainInstance.damageEvent.id }
								label={ domainInstance && domainInstance.damageEvent && domainInstance.damageEvent.label }
								name={'damageEvent'}
								createable={{formElements: <DamageEventFormElements/>, target:'eventName'}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamageType">
							<Form.Label>{t('damage.damageType')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"damageType"}
								sort={"edCause.description_sort"}
								value={ domainInstance && domainInstance.damageType && domainInstance.damageType.id }
								label={ domainInstance && domainInstance.damageType && domainInstance.damageType.label }
								name={'damageType'}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} controlId="formGroupDamageNote">
							<Form.Label>{t('damage.description')}</Form.Label>
							<FormControl
								name={"note"}
								type={"text"}
								as="textarea" rows={10}
								value={domainInstance.note || (defaultValues && defaultValues.note)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
