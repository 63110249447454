import React from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import FormControl from "../_components/FormControl";
import {useTranslation} from "react-i18next";

function AdjustmentsRequest( {show, setShow, onSubmit} ) {
	const useFormObject = useForm();
	const {t} = useTranslation();

	const handleSubmit = (data) => {
		setShow(false)
		onSubmit(data)
	}

	return (
		<Modal show={show} onHide={ () => setShow(false) } >
			<Modal.Header closeButton className={"bg-primary text-white"}>
				{ t('adjustmentsRequest.header') }
			</Modal.Header>

			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit( handleSubmit )}>
					<Modal.Body>
						<Row>
							<Col>
								<Form.Group as={Col} md="12" controlId="message">
									<Form.Label>{ t('adjustmentsRequest.message.label') }</Form.Label>
									<FormControl
										name={"message"}
										type={"text"}
										as="textarea" rows={10}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button variant={ "primary" } type={"submit"}>{ t( 'default.send' ) }</Button>
						<Button variant={ "secondary" } className={"ms-2"} onClick={ () => setShow(false) } >{ t( 'default.cancel' ) }</Button>
					</Modal.Footer>
				</Form>
			</FormProvider>
		</Modal>
	)
}

export default AdjustmentsRequest